const safeParseJSON = (val: any) => {
  try {
    return JSON.parse(val);
  } catch (err) {
    return val;
  }
};

const _env = {
  ...process.env,
  ...((window as any).process ? (window as any).process.env : {})
};

export const env = (
  name: string,
  defaultValue?: string | number | boolean | null
): string | number | boolean | null | undefined => {
  const prefixedName = `REACT_APP_${name}`;

  if (name in _env) {
    return safeParseJSON(_env[name]);
  } else if (prefixedName in _env) {
    return safeParseJSON(_env[prefixedName]);
  } else if (!!defaultValue) {
    return defaultValue;
  } else {
    throw Error(`Missing environment variable '${name}'`);
  }
};
