import { AnyAction } from 'redux';
import { stateBranch } from 'redux-state-branch';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';
import * as utils from './utils';
import { SchoolT, SchoolStateT, defaultState, name } from './defs';

export * from './defs';

const reducer = (state: SchoolStateT = defaultState, action: AnyAction) => {
  switch (action.type) {
    case schoolBranch.constant.CHANGE_SELECTED_TIMEFRAME:
      return {
        ...state,
        selectedTimeframe: action.meta.selectedTimeframe
      };
    case schoolBranch.constant.UPDATE_POINT_TRANSACTIONS:
      const payload = action.items[0] || {};
      const school = state.items[payload.id];

      if (!school) {
        throw new Error(`No school found`);
      }

      const newSchool = {
        ...school,
        points: {
          ...school.points,
          ...payload.points
        }
      };

      return {
        ...state,
        items: {
          ...state.items,
          ...{ [school.id]: newSchool }
        }
      };
    case schoolBranch.constant.FETCH_GIVE_POINTS:
      return {
        ...state,
        loading: {
          ...state.loading,
          givePoints: true
        }
      };
    case schoolBranch.constant.FETCH_GIVE_POINTS_COMPLETE:
      return {
        ...state,
        loading: {
          ...state.loading,
          givePoints: false,
          givePointsSuccess: !!action.data.isSuccess
        }
      };

    default:
      return state;
  }
};

export const schoolBranch = stateBranch<SchoolT, SchoolStateT>()({
  name,
  constants,
  actions,
  selectors,
  defaultState,
  utils,
  reducer
});
