import { Dispatch } from 'redux';
import { name, START_WATCH, defaultHouse } from './defs';
import { createActions } from 'redux-state-branch';
import { housesWatcher } from '@state/query';

export const { create, update, ...rest } = createActions({
  name,
  defaultItem: defaultHouse
});

export const watchHouses = (schoolId: string) => {
  return (dispatch: Dispatch) => {
    const unwatch = housesWatcher({
      schoolId,
      onHouse: houses => {
        dispatch(update(houses));
      }
    });

    dispatch({
      type: START_WATCH
    });

    return unwatch;
  };
};
