import { stateBranch } from 'redux-state-branch';
import * as actions from './actions';
import * as selectors from './selectors';
import * as constants from './constants';
import { HouseT, HouseStateT, name } from './defs';

export * from './defs';

export const houseBranch = stateBranch<HouseT, HouseStateT>()({
  name,
  constants,
  actions,
  selectors
});
