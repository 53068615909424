import React from 'react';
import { SchoolT } from '@state/school';
import logoUrl from '@common/assets/logo.png';

import { Link } from 'react-router-dom';

import { Card, CardPrimaryAction, CardMedia } from '@rmwc/card';

import { Typography } from '@rmwc/typography';

import { AppBar } from '@components/app-bar';
import styles from './home.module.css';

type HomeMainPropsT = {
  schools: SchoolT[];
};

function SchoolCard({ school }: { school: SchoolT }) {
  return (
    <Card className={styles.card}>
      <CardPrimaryAction tag={Link} {...{ to: school.id }}>
        <CardMedia
          square
          style={{
            background: `${school.themePrimary || ''} url(${school.logo ||
              ''}) scroll no-repeat center center / cover`
          }}
        />
        <div style={{ padding: '0 1rem' }}>
          <Typography use="headline6" tag="h2">
            {school.name}
          </Typography>
        </div>
      </CardPrimaryAction>
    </Card>
  );
}

export function HomeMain({ schools }: HomeMainPropsT) {
  return (
    <>
      <AppBar>
        <div className={styles.logo}>
          <img src={logoUrl} alt="Leader.Live" />
        </div>
      </AppBar>

      <Banner />

      <div className={styles.cards}>
        {schools.map(school => (
          <SchoolCard key={school.id} school={school} />
        ))}
      </div>

      <div className={styles.footer} />
    </>
  );
}

function Banner() {
  return (
    <div className={styles.banner}>
      <div>
        <p>
          Leader.Live is the easiest, most intuitive house management platform
          for your school.
        </p>
        {/* <Button
          className={styles.bannerButton}
          label="Find out More"
          outlined
          trailingIcon="arrow_forward"
        /> */}
      </div>
    </div>
  );
}
