import React from 'react';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';

export class Online extends React.Component<
  { children: React.ReactNode },
  { offline: boolean }
> {
  state = {
    offline: !window.navigator.onLine
  };

  componentDidMount() {
    window.addEventListener('offline', () => this.setState({ offline: true }));
    window.addEventListener('online', () => this.setState({ offline: false }));
  }

  render() {
    if (this.state.offline) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem'
          }}
        >
          <Icon icon="wifi_off" style={{ fontSize: '4rem', color: 'red' }} />
          <Typography use="headline6" tag="h2">
            You're Offline!
          </Typography>
          <Typography use="body1" tag="h3">
            Leader.Live requires an internet connection to work properly. Please
            connect to a network.
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}
