import 'react-hot-loader';
import './styles.tsx';

import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider as ReduxProvider } from 'react-redux';
import { App } from './app';
import * as serviceWorker from './register-service-worker';
import { store } from './state';
import { isIOSPWA } from '@common/platform';
import { history } from '@common/history';
import { env } from '@common/env';
import { Online } from '@components/online';
import { notify } from '@common/notifications';

const init = () => {
  initIOSPWANavigationFix();
  initAnalytics();
  initMoment();
  renderApp();
  initServiceWorker();
  serviceWorkerReinitFix();
};

const initMoment = () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'just now',
      ss: '%d sec',
      m: '1 min',
      mm: '%d min',
      h: 'an hr',
      hh: '%d hrs',
      d: '1 d',
      dd: '%d days',
      M: '1 m',
      MM: '%d m',
      y: 'a yr',
      yy: '%d yrs'
    }
  });
};

const initServiceWorker = (immediate?: boolean) => {
  serviceWorker.register({
    immediate: !!immediate,
    onUpdate: () => {
      notify({
        title: 'An update to Leader.Live is available. Restarting...',
        icon: 'update'
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    }
  });
};

const serviceWorkerReinitFix = () => {
  //startSimulation and pauseSimulation defined elsewhere
  function handleVisibilityChange() {
    if (!document.hidden) {
      console.log('Reinit service worker');
      initServiceWorker(true);
    }
  }

  document.addEventListener('visibilitychange', handleVisibilityChange, false);
};

const initAnalytics = () => {
  const trackingId = env('REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID', null);
  if (env('NODE_ENV', false) === 'production' && trackingId) {
    ReactGA.initialize(String(trackingId));

    // track exceptions
    window.onerror = function(msg, url, line, col, error) {
      // Note that col & error are new to the HTML 5 spec and may not be
      // supported in every browser.  It worked for me in Chrome.
      var extra = !col ? '' : '\ncolumn: ' + col;
      extra += !error ? '' : '\nerror: ' + error;

      // You can view the information in an alert to see things working like this:
      ReactGA.exception({
        description:
          'Error: ' + msg + '\nurl: ' + url + '\nline: ' + line + extra,
        fatal: true
      });

      var suppressErrorAlert = true;
      // If you return true, then error alerts (like in older versions of
      // Internet Explorer) will be suppressed.
      return suppressErrorAlert;
    };

    // listen to page views
    history.listen((location, action) => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    });

    // record the initial page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};

const initIOSPWANavigationFix = () => {
  if (isIOSPWA && ['', '/'].includes(window.location.pathname)) {
    const key = 'iosPWARestorePath';
    const previousRoute = window.localStorage.getItem(key);
    previousRoute && history.push(previousRoute);

    history.listen(route => {
      window.localStorage.setItem(key, route.pathname + route.search);
    });
  }
};

const renderApp = () => {
  ReactDOM.render(
    <ReduxProvider store={store}>
      <Online>
        <App />
      </Online>
    </ReduxProvider>,

    document.getElementById('root')
  );
};

init();
