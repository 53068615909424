import { DispatchT } from '@state';
import {
  TimeRangeT,
  PointTransactionT,
  PointsStateT,
  defaultPointTransaction,
  name
} from './defs';
import { createActions } from 'redux-state-branch';
import { pointsWatcher, pointsFetchQuery } from '@state/query';

export const {
  create,
  update,
  replace,
  remove,
  setMeta,
  reset
} = createActions<PointTransactionT, PointsStateT>({
  name,
  defaultItem: defaultPointTransaction
});

/** Fetch points transactions for a school */
export const fetchPointTransactions = ({
  schoolId,
  classroomId,
  timeRange
}: {
  schoolId: string;
  classroomId?: string;
  timeRange?: TimeRangeT;
}) => {
  return async (dispatch: DispatchT) => {
    const points = await pointsFetchQuery({
      schoolId,
      classroomId,
      timeRange
    });

    dispatch(create(points));
  };
};

export const watchPointTransactions = (schoolId: string) => {
  return (dispatch: DispatchT) => {
    return pointsWatcher({
      schoolId,
      onPoint: points => {
        dispatch(create(points));
      }
    });
  };
};
