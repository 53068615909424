import React from 'react';
import { AppBar } from '@components/app-bar';
import { SchoolT } from '@state/school';
import { ClassroomTimeRangeOptions } from '@state/point';
import { ClassroomT } from '@state/classroom';
import { HouseT } from '@state/house';
import { Leaderboard } from '@components/leaderboard';
import { View } from '@components/view';
import styles from './classroom.module.css';
import { SimpleMenu, MenuItem } from '@rmwc/menu';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import { TopAppBarActionItem } from '@rmwc/top-app-bar';
import { ListDivider, ListItemGraphic } from '@rmwc/list';
import { prompt, confirm } from '@common/dialogs';
import { Button } from '@rmwc/button';
import { TopAppBarRow, TopAppBarFixedAdjust } from '@rmwc/top-app-bar';
import { TabBar, Tab } from '@rmwc/tabs';

export function ClassroomMain({
  school,
  classroom,
  classrooms,
  pointsMap,
  houses,
  timeframe,
  onMenuToggle,
  onClassroomChange,
  onAddClassroom,
  onRemoveClassroom,
  onTimeframeChange
}: {
  school: SchoolT;
  classroom?: ClassroomT;
  classrooms: ClassroomT[];
  pointsMap: { [houseId: string]: number };
  houses: HouseT[];
  timeframe: ClassroomTimeRangeOptions;
  onMenuToggle: () => void;
  onClassroomChange: (classroomId: string) => void;
  onAddClassroom: (name: string) => void;
  onRemoveClassroom: (classroomId: string) => void;
  onTimeframeChange: (timeframe: ClassroomTimeRangeOptions) => void;
}) {
  const doNewClassroom = async () => {
    const classroomName = await prompt({
      title: 'New Classroom',
      body: 'Give your classroom a unique name.',
      inputProps: {
        outlined: true
      }
    });

    classroomName && onAddClassroom(classroomName);
  };

  return (
    <>
      <ClassroomAppBar
        school={school}
        classroom={classroom}
        classrooms={classrooms}
        timeframe={timeframe}
        doNewClassroom={doNewClassroom}
        onMenuToggle={onMenuToggle}
        onClassroomChange={onClassroomChange}
        onRemoveClassroom={onRemoveClassroom}
        onTimeframeChange={onTimeframeChange}
      />
      {!!classroom ? (
        <ClassroomLeaderboard houses={houses} pointsMap={pointsMap} />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem'
          }}
        >
          <Icon icon="school" style={{ fontSize: '4rem' }} />
          <Typography use="headline6" tag="h2">
            My Classroom
          </Typography>
          <Typography use="body1" tag="h3">
            Track points and view leaderboards for your individual classrooms.
          </Typography>
          <br />

          <Button
            onClick={doNewClassroom}
            icon="add"
            label="New Classroom"
            raised
          />
        </div>
      )}
    </>
  );
}

function ClassroomAppBar({
  school,
  classroom,
  classrooms,
  timeframe,
  doNewClassroom,
  onMenuToggle,
  onClassroomChange,
  onRemoveClassroom,
  onTimeframeChange
}: {
  school: SchoolT;
  doNewClassroom: () => void;
  classroom?: ClassroomT;
  classrooms: ClassroomT[];
  timeframe: ClassroomTimeRangeOptions;
  onMenuToggle: () => void;
  onClassroomChange: (classroomId: string) => void;
  onRemoveClassroom: (classroomId: string) => void;
  onTimeframeChange: (timeframe: ClassroomTimeRangeOptions) => void;
}) {
  const doRemoveClassroom = async () => {
    if (!classroom) return;
    const shouldDelete = await confirm({
      title: 'Delete Classroom',
      body: `Are you sure you want to delete the classroom "${classroom.name}"?.`
    });

    !!shouldDelete && onRemoveClassroom(classroom.id);
  };

  return (
    <>
      <AppBar
        onMenuToggle={onMenuToggle}
        divider
        additionalRow={
          !!classroom && (
            <TopAppBarRow>
              <TabBar
                activeTabIndex={[
                  ClassroomTimeRangeOptions.today,
                  ClassroomTimeRangeOptions.week,
                  ClassroomTimeRangeOptions.all
                ].indexOf(timeframe)}
              >
                <Tab
                  onClick={() =>
                    onTimeframeChange(ClassroomTimeRangeOptions.today)
                  }
                  label="Today"
                />
                <Tab
                  onClick={() =>
                    onTimeframeChange(ClassroomTimeRangeOptions.week)
                  }
                  label="This Week"
                />
                <Tab
                  onClick={() =>
                    onTimeframeChange(ClassroomTimeRangeOptions.all)
                  }
                  label="All Time"
                />
              </TabBar>
            </TopAppBarRow>
          )
        }
      >
        {!!classroom && (
          <div className={styles.classroomPicker}>
            <SimpleMenu
              handle={
                <Typography use="headline6">
                  {classroom.name}{' '}
                  <Icon
                    className={styles.classroomPickerCaret}
                    icon="arrow_drop_down"
                  />
                </Typography>
              }
            >
              {classrooms.map(classroom => (
                <MenuItem
                  key={classroom.id}
                  onClick={() => onClassroomChange(classroom.id)}
                >
                  {classroom.name}
                </MenuItem>
              ))}
              <ListDivider />
              <MenuItem onClick={doNewClassroom}>
                <ListItemGraphic icon="add" />
                New Classroom
              </MenuItem>
            </SimpleMenu>
          </div>
        )}
        <span style={{ flex: 1 }} />

        {!!classroom && (
          <SimpleMenu handle={<TopAppBarActionItem icon="more_horizontal" />}>
            <MenuItem onClick={doRemoveClassroom}>
              Delete "{classroom.name}"
            </MenuItem>
          </SimpleMenu>
        )}
      </AppBar>
      <TopAppBarFixedAdjust />
    </>
  );
}

function ClassroomLeaderboard({
  houses,
  pointsMap
}: {
  houses: HouseT[];
  pointsMap: { [houseId: string]: number };
}) {
  return (
    <View className={styles.view}>
      <Leaderboard houses={houses} pointsMap={pointsMap} />
    </View>
  );
}
