import { createSelectors } from 'redux-state-branch';
import { UserT, UserStateT, name } from './defs';
import { StateT } from '@state';

export const { where, byId, ...rest } = createSelectors<UserT, UserStateT>({
  name
});

export const forSchool = (state: StateT, schoolCode: string): UserT[] => {
  return where(state, {
    callback: u => u.schoolCode.includes(schoolCode)
  }).sort((a: UserT, b: UserT) => (a.firstName > b.firstName ? 1 : -1));
};
