export const isSafari =
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1;

export const isIOSPWA =
  isSafari && (window.navigator as any).standalone === true;
export const isIphoneX =
  navigator.platform === 'iPhone' &&
  window.screen.width === 375 &&
  window.screen.height === 812;
export const isChromePWA = window.matchMedia('(display-mode: standalone)')
  .matches;

export const isPWA = isIOSPWA || isChromePWA;
