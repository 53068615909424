import { PointTransactionT } from '@state/point';

export const name = 'classroom';

export type ClassroomT = {
  id: string;
  name: string;
  userId: string;
  schoolId: string;
  points: { [key: string]: PointTransactionT };
};

export type ClassroomStateT = {
  items: { [id: string]: ClassroomT };
};

export const defaultClassroom: Partial<ClassroomT> = {
  points: {}
};
