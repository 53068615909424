import React from 'react';
import ReactDOM from 'react-dom';
import { isIOSPWA, isIphoneX } from '@common/platform';

type IOSStatusBarPropsT = {
  color?: null | string;
  backgroundColor?: null | string;
};

const statusBarDomNode = document.getElementById('ios-status-bar');

export const IOSStatusBar = ({
  color,
  backgroundColor
}: IOSStatusBarPropsT) => {
  color = color || '#333';
  backgroundColor = backgroundColor || 'white';

  if (isIOSPWA && statusBarDomNode) {
    const height = isIphoneX ? 44 : 21;

    return ReactDOM.createPortal(
      <React.Fragment>
        <style>{`
            html {
              background-color: ${color};
            }
        `}</style>
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: '100vh',
            width: '100vw',
            backgroundColor
          }}
        />

        <div
          style={{
            position: 'fixed',
            top: `-${height}px`,
            height: `${height}.1px`,
            left: 0,
            right: 0,
            width: '100vw',
            backgroundColor: color,
            zIndex: 9999,
            transform: 'translateZ(0)'
          }}
        />
      </React.Fragment>,
      statusBarDomNode
    );
  }

  return null;
};
