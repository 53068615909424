import { createConstant } from 'redux-state-branch';

export const name = 'house';

export type HouseT = {
  id: string;
  name: string;
  totalPoints: number;
  color: string;
  schoolId: string;
  image: string | null;
};

export type HouseStateT = {
  items: {
    [key: string]: HouseT;
  };
};

export const defaultHouse = {
  name: 'Untitled House',
  totalPoints: 0,
  color: 'black',
  image: null
};

export const START_WATCH = createConstant(name, 'START_WATCH');
