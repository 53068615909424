//@flow
import { TimeRangeT } from '.';
import moment from 'moment';
import { SchoolT, defaultTimeframes, defaultSchool } from './defs';
import { PointTransactionT, defaultPointTransaction } from '@state/point';

export const TIMEFRAMES = {
  today: 'today',
  thisWeek: 'thisWeek',
  lastWeek: 'lastWeek',
  thisMonth: 'thisMonth',
  thisQuarter: 'thisQuarter',
  thisYear: ''
};

export const convertTimeframeToUnixMillisecondsRange = (
  timeframe: keyof typeof TIMEFRAMES
): TimeRangeT => {
  switch (timeframe) {
    case 'today':
    case 'thisWeek':
    case 'thisMonth':
    case 'thisQuarter':
    case 'thisYear':
      const relativeTimeframe = {
        today: 'day',
        thisWeek: 'week',
        thisMonth: 'month',
        thisQuarter: 'quarter',
        thisYear: 'year'
      }[timeframe] as moment.unitOfTime.StartOf;

      return [
        moment()
          .startOf(relativeTimeframe)
          .utc()
          .unix() * 1000,
        moment()
          .endOf(relativeTimeframe)
          .utc()
          .unix() * 1000
      ];
    case 'lastWeek':
      return [
        moment()
          .startOf('week')
          .subtract(7, 'd')
          .utc()
          .unix() * 1000,
        moment()
          .startOf('week')
          .utc()
          .unix() * 1000
      ];
    default:
      return [-Infinity, Infinity];
  }
};

export const transformSchool = (school: { [key: string]: any }): SchoolT => {
  let timeframes;
  let year = defaultTimeframes.year;
  let period = defaultTimeframes.period;

  if (school.timeframes) {
    if (school.timeframes.year) {
      const y: number[] = school.timeframes.year;
      const yearStart = moment()
        .month(y[0] - 1)
        .date(y[1]);

      if (yearStart > moment()) {
        yearStart.add(-1, 'year');
      }

      const yearEnd = moment(yearStart)
        .month(y[2] - 1)
        .date(y[3])
        .add(1, 'year');
      year = [yearStart.utc().unix() * 1000, yearEnd.utc().unix() * 1000];
    }

    if (school.timeframes.period) {
      let firstPeriod: moment.Moment;
      // @ts-ignore
      period = Object.entries(school.timeframes.period).reduce<{
        [key: string]: [number, number];
      }>((acc, [periodName, p]: any) => {
        const periodStart = moment()
          .month(p[0] - 1)
          .date(p[1]);

        firstPeriod = !firstPeriod ? moment(periodStart) : firstPeriod;

        const periodEnd = moment()
          .month(p[2] - 1)
          .date(p[3]);

        if (periodStart.month() < firstPeriod.month())
          periodStart.add(1, 'year');
        if (periodEnd.month() <= firstPeriod.month()) periodEnd.add(1, 'year');

        acc[periodName] = [
          periodStart.utc().unix() * 1000,
          periodEnd.utc().unix() * 1000
        ];
        return acc;
      }, {});
    }

    timeframes = {
      year,
      period
    };
  } else {
    timeframes = defaultSchool.timeframes;
  }

  return {
    ...defaultSchool,
    ...school,
    // @ts-ignore
    timeframes
  };
};

export const transformPointTransaction = (point: Object): PointTransactionT =>
  ({
    ...defaultPointTransaction,
    ...point
  } as PointTransactionT);
