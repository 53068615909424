import { auth } from '@common/auth';
import { Location } from 'history';

type AuthCallbackPropsT = {
  location: Location;
};

export const AuthCallback = ({ location }: AuthCallbackPropsT) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
  return null;
};
