import { name } from './defs';
import { createConstant } from 'redux-state-branch';

export const UPDATE_POINT_TRANSACTIONS = createConstant(
  name,
  'UPDATE_POINT_TRANSACTIONS'
);

export const CHANGE_SELECTED_TIMEFRAME = createConstant(
  name,
  'CHANGE_SELECTED_TIMEFRAME'
);

export const FETCH_GIVE_POINTS = createConstant(name, 'FETCH_GIVE_POINTS');

export const FETCH_GIVE_POINTS_COMPLETE = createConstant(
  name,
  'FETCH_GIVE_POINTS_COMPLETE'
);

export const ADD_POINT_TRANSACTION_TO_FEED = createConstant(
  name,
  'ADD_POINT_TRANSACTION_TO_FEED'
);

export const UPDATE_AVAILABLE_REWARDS = createConstant(
  name,
  'UPDATE_AVAILABLE_REWARDS'
);
