import { ClassroomT, defaultClassroom } from '@state/classroom';
import {
  PointTransactionT,
  TimeRangeT,
  defaultPointTransaction,
} from '@state/point';
import { SchoolT, schoolBranch, AwardT } from '@state/school';
import { HouseT, defaultHouse } from '@state/house';
import { db, functions } from '@state/firebase';

const getToken = () => {
  return window.localStorage.getItem('id_token');
};

/****************************************************************
 * Classrooms
 ****************************************************************/
export const classroomsFetchForUserAtSchoolQuery = async ({
  userId,
  schoolId,
}: {
  userId: string;
  schoolId: string;
}): Promise<ClassroomT[]> => {
  const items: ClassroomT[] = await db
    .collection('classrooms')
    .where('userId', '==', userId)
    .where('schoolId', '==', schoolId)
    .get()
    .then((res) =>
      res.docs.map((d) => {
        const { userId, name, schoolId } = d.data();
        return {
          ...defaultClassroom,
          id: d.id,
          name,
          userId,
          schoolId,
        } as ClassroomT;
      })
    );

  return items;
};

export const classroomCreateQuery = async ({
  schoolId,
  name,
}: {
  schoolId: string;
  name: string;
}): Promise<ClassroomT> => {
  const classroomResp = await functions.httpsCallable('createClassroom')({
    schoolId,
    name,
    idToken: getToken(),
  });

  return { ...defaultClassroom, ...classroomResp.data } as ClassroomT;
};

export const classroomRemoveQuery = async ({
  classroomId,
}: {
  classroomId: string;
}): Promise<boolean> => {
  await functions.httpsCallable('removeClassroom')({
    classroomId,
    idToken: getToken(),
  });

  return true;
};

/****************************************************************
 * Houses
 ****************************************************************/
export const housesWatcher = ({
  schoolId,
  onHouse,
}: {
  schoolId: string;
  onHouse: (houses: HouseT[]) => void;
}) => {
  return db
    .collection('houses')
    .where('schoolId', '==', schoolId)
    .onSnapshot((collection) => {
      const houses = collection.docs.map(
        (d) =>
          ({
            ...defaultHouse,
            ...d.data(),
            id: d.id,
          } as HouseT)
      );

      onHouse(houses);
    });
};

/****************************************************************
 * Points
 ****************************************************************/
export const pointsFetchQuery = async ({
  schoolId,
  classroomId,
  timeRange,
}: {
  schoolId: string;
  classroomId?: string;
  timeRange?: TimeRangeT;
}): Promise<PointTransactionT[]> => {
  let query: firebase.default.firestore.Query = db
    .collection('schools')
    .doc(schoolId)
    .collection('points');

  if (classroomId) {
    query = query.where('classroomId', '==', classroomId);
  }

  if (timeRange) {
    const [startTime, endTime] = timeRange;
    query = query
      .where('createdAt', '>=', startTime)
      .where('createdAt', '<=', endTime);
  }

  const points = await query.get().then((res) =>
    res.docs.map((d) => {
      const data = d.data();
      return {
        ...defaultPointTransaction,
        ...data,
        id: d.id,
        schoolId,
        awardReason: data.awardReason || '',
        awardValue: parseInt(data.awardValue),
      } as PointTransactionT;
    }, {})
  );

  return points;
};

export const pointsWatcher = ({
  schoolId,
  onPoint,
}: {
  schoolId: string;
  onPoint: (point: PointTransactionT[]) => void;
}) => {
  return db
    .collection('schools')
    .doc(schoolId)
    .collection('points')
    .orderBy('createdAt', 'desc')
    .limit(10)
    .onSnapshot((collection) => {
      const points: PointTransactionT[] = [];
      for (const doc of collection.docs) {
        const point = {
          ...defaultPointTransaction,
          id: doc.id,
          schoolId,
          ...doc.data(),
        } as PointTransactionT;

        points.push(point);
      }

      onPoint(points);
    });
};

/****************************************************************
 * Schools
 ****************************************************************/
export const schoolResetPointsQuery = async ({
  schoolId,
}: {
  schoolId: string;
}) => {
  await functions.httpsCallable('resetPointsForSchool')({
    schoolId,
    idToken: getToken(),
  });

  return true;
};

export const schoolToggleBlackoutQuery = async ({
  schoolId,
  isBlackout,
}: {
  schoolId: string;
  isBlackout: boolean;
}) => {
  await functions.httpsCallable('toggleBlackoutForSchool')({
    schoolId,
    isBlackout,
    idToken: getToken(),
  });

  return true;
};

export const schoolFetchByIdWatcher = ({
  id,
  onSchool,
}: {
  id: string;
  onSchool: (school: SchoolT) => void;
}) => {
  db.collection('schools')
    .doc(id)
    .onSnapshot((doc) => {
      if (doc.exists) {
        onSchool(schoolBranch.util.transformSchool(doc.data()!));
      }
    });
};

export const schoolFetchAllQuery = async () => {
  const qs = await db.collection('schools').get();
  return qs.docs.map((doc) => schoolBranch.util.transformSchool(doc.data()));
};

export const schoolGivePointsQuery = async ({
  schoolId,
  houseId,
  classroomId,
  awardName,
  awardValue,
  studentName,
  awardReason,
}: {
  schoolId: string;
  houseId: string;
  classroomId?: string;
  awardName?: string;
  awardValue?: number;
  studentName: null | string;
  awardReason: null | string;
}) => {
  return await functions.httpsCallable('givePoints')({
    schoolId,
    houseId,
    classroomId,
    awardName,
    awardValue,
    studentName,
    awardReason,
    idToken: getToken(),
  });
};

export const schoolUpdateAwardsQuery = async ({
  schoolId,
  availableAwards,
}: {
  schoolId: string;
  availableAwards: AwardT[];
}) => {
  await functions.httpsCallable('updateAvailableAwardsForSchool')({
    schoolId,
    availableAwards,
    idToken: getToken(),
  });

  return true;
};

/****************************************************************
 * User
 ****************************************************************/

export const userRemoveFromSchoolQuery = async ({
  schoolId,
  userId,
}: {
  schoolId: string;
  userId: string;
}) => {
  return await functions.httpsCallable('removeUserFromSchool')({
    schoolId,
    userId,
    idToken: getToken(),
  });
};

export const userToggleAdminPrivilegesQuery = async ({
  schoolId,
  userId,
  isAdmin,
}: {
  schoolId: string;
  userId: string;
  isAdmin: boolean;
}) => {
  return await functions
    .httpsCallable('toggleAdminPrivilegesForUser')({
      schoolId,
      userId,
      isAdmin,
      idToken: getToken(),
    })
    .then((res) => res.data);
};

export const userFetchAllForSchoolQuery = async ({
  schoolId,
}: {
  schoolId: string;
}): Promise<
  Array<{
    user_id: string;
    email: string;
    avatar: string;
    last_login: string;
    user_metadata: {
      firstName: string;
      lastName: string;
    };
    app_metadata: {
      admin?: [];
      lastName: string;
      schoolCode: string;
    };
  }>
> => {
  return await functions
    .httpsCallable('getUsersForSchool')({
      schoolId,
      idToken: getToken(),
    })
    .then((res) => res.data);
};
