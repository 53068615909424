export const name = 'user';

export type UserT = {
  id: string;
  admin: string[];
  schoolCode: string[];
  firstName: string;
  lastName: string;
  name: string;
  avatar: null | string;
  email: string;
  lastLogin: string;
};

export type UserStateT = {
  items: {
    [userId: string]: UserT;
  };
};

export const defaultUser: UserT = {
  id: '',
  admin: [],
  schoolCode: [],
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  avatar: null,
  lastLogin: ''
};

export const defaultState: UserStateT = {
  items: {}
};
