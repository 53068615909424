import React from 'react';
// @ts-ignore
import * as Proton from 'proton-js';

type FireworksParticlePropsT = {
  color: string;
} & React.HTMLProps<any>;

export class FireworksParticle extends React.Component<
  FireworksParticlePropsT
> {
  canvasRef: HTMLCanvasElement | null = null;
  proton: any;
  unmounted = false;

  componentDidMount() {
    const canvas = this.canvasRef;
    if (!canvas) return;
    var context: CanvasRenderingContext2D | null;
    var proton: any;
    var renderer;
    var emitter;

    const main = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      context = canvas.getContext('2d');
      //context.globalCompositeOperation = "lighter";

      createProton();
      tick();
    };

    const createProton = () => {
      this.proton = proton = new Proton();
      emitter = new Proton.Emitter();
      emitter.rate = new Proton.Rate(new Proton.Span(1, 3), 0.5);

      emitter.addInitialize(new Proton.Mass(1));
      emitter.addInitialize(new Proton.Radius(4, 6));
      emitter.addInitialize(
        new Proton.P(
          new Proton.LineZone(
            10,
            canvas.height,
            canvas.width - 10,
            canvas.height
          )
        )
      );
      emitter.addInitialize(new Proton.Life(1, 1.5));
      emitter.addInitialize(
        new Proton.V(
          new Proton.Span(4, 6),
          new Proton.Span(0, 0, true),
          'polar'
        )
      );

      emitter.addBehaviour(new Proton.Gravity(1));
      emitter.addBehaviour(
        new Proton.Color(
          this.props.color || '#ff0000',
          this.props.color || 'random'
        )
      );
      emitter.emit();
      proton.addEmitter(emitter);

      renderer = new Proton.CanvasRenderer(canvas);
      renderer.onProtonUpdate = function() {
        context && (context.fillStyle = 'rgba(0, 0, 0, 0.1)');
        context && context.fillRect(0, 0, canvas.width, canvas.height);
      };
      proton.addRenderer(renderer);

      ////NOTICE :you can only use two emitters do this effect.In this demo I use more emitters want to test the emtter's life
      proton.addEventListener(Proton.PARTICLE_DEAD, function(particle: any) {
        if (Math.random() < 0.7) createFirstEmitter(particle);
        else createSecondEmitter(particle);
      });
    };

    const createFirstEmitter = (particle: any) => {
      var subemitter = new Proton.Emitter();
      subemitter.rate = new Proton.Rate(new Proton.Span(250, 300), 1);
      subemitter.addInitialize(new Proton.Mass(1));
      subemitter.addInitialize(new Proton.Radius(1, 2));
      subemitter.addInitialize(new Proton.Life(1, 3));
      subemitter.addInitialize(
        new Proton.V(new Proton.Span(2, 4), new Proton.Span(0, 360), 'polar')
      );

      subemitter.addBehaviour(new Proton.RandomDrift(10, 10, 0.05));
      subemitter.addBehaviour(new Proton.Alpha(1, 0));
      subemitter.addBehaviour(new Proton.Gravity(3));
      var color = this.props.color
        ? this.props.color
        : Math.random() > 0.3
        ? Proton.MathUtils.randomColor()
        : 'random';

      subemitter.addBehaviour(new Proton.Color(color));

      subemitter.p.x = particle.p.x;
      subemitter.p.y = particle.p.y;
      subemitter.emit('once', true);
      proton.addEmitter(subemitter);
    };

    const createSecondEmitter = (particle: any) => {
      var subemitter = new Proton.Emitter();
      subemitter.rate = new Proton.Rate(new Proton.Span(100, 120), 1);

      subemitter.addInitialize(new Proton.Mass(1));
      subemitter.addInitialize(new Proton.Radius(4, 8));
      subemitter.addInitialize(new Proton.Life(1, 2));
      subemitter.addInitialize(
        new Proton.V([1, 2], new Proton.Span(0, 360), 'polar')
      );

      subemitter.addBehaviour(new Proton.Alpha(1, 0));
      subemitter.addBehaviour(new Proton.Scale(1, 0.1));
      subemitter.addBehaviour(new Proton.Gravity(1));
      var color = this.props.color || Proton.MathUtils.randomColor();

      subemitter.addBehaviour(new Proton.Color(color));

      subemitter.p.x = particle.p.x;
      subemitter.p.y = particle.p.y;
      subemitter.emit('once', true);
      proton.addEmitter(subemitter);
    };

    const tick = () => {
      !this.unmounted && requestAnimationFrame(tick);
      proton.update();
    };

    main();
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.proton.destroy();
  }

  render() {
    const { color, ...rest } = this.props;
    return <canvas {...rest} ref={ref => (this.canvasRef = ref)} />;
  }
}
