import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { houseBranch, HouseStateT } from './house';
import { schoolBranch, SchoolStateT } from './school';
import { currentUserBranch, CurrentUserStateT } from './current-user';
import { userBranch, UserStateT } from './user';
import { classroomBranch, ClassroomStateT } from './classroom';
import thunk from 'redux-thunk';
import { pointBranch } from './point';
import { PointsStateT } from './point/defs';

export type StateT = {
  house: HouseStateT;
  school: SchoolStateT;
  currentUser: CurrentUserStateT;
  classroom: ClassroomStateT;
  user: UserStateT;
  point: PointsStateT;
};

export const rootReducer = combineReducers({
  [houseBranch.name]: houseBranch.reducer,
  [schoolBranch.name]: schoolBranch.reducer,
  [currentUserBranch.name]: currentUserBranch.reducer,
  [userBranch.name]: userBranch.reducer,
  [pointBranch.name]: pointBranch.reducer,
  [classroomBranch.name]: classroomBranch.reducer
});

export type GetStateT = () => StateT;

export type ActionT<args = {}> = {
  type: string;
} & args;
export type PromiseActionT = Promise<ActionT>;
export type ThunkActionT = (dispatch: DispatchT, getState: GetStateT) => void;

export type DispatchT = (
  action: ActionT<any> | ThunkActionT | PromiseActionT
) => any;

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  !!(window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
