import React, { useState, useCallback, useEffect } from 'react';
import { Fab } from '@rmwc/fab';
import {
  List,
  ListGroup,
  ListGroupSubheader,
  SimpleListItem
} from '@rmwc/list';
import { Button } from '@rmwc/button';
import { HouseT } from '@state/house';
import { AwardT, SchoolT } from '@state/school';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Typography } from '@rmwc/typography';
import classNames from 'classnames';
import styles from './give-points.module.css';
import { Spinner } from '@components/spinner';
import { Checkmark } from '@components/checkmark';
import { ClassroomT } from '@state/classroom';

const AWARD_MAX_LENGTH = 4;

const formatAwardText = (awardName: string, awardValue: number | string) =>
  `${awardName} (${awardValue} point${Number(awardValue) !== 1 ? 's' : ''})`;

const isValidAwardValue = (awardValue?: string | number | null): boolean => {
  const val = Number(awardValue);
  return !(isNaN(val) || val === 0);
};

export function GivePointsMain({
  houses,
  school,
  isGivingPoints,
  classrooms,
  isAdmin,
  open,
  exited,
  setOpen,
  onPointAward
}: {
  houses: HouseT[];
  school: SchoolT;
  isGivingPoints: boolean | 'success';
  exited: boolean;
  classrooms: ClassroomT[];
  isAdmin: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  onPointAward: (opts: {
    houseId: string;
    classroomId?: string;
    award: AwardT;
    studentName: null | string;
    awardReason: null | string;
  }) => void;
}) {
  const [selectedHouseId, setSelectedHouseId] = useState<string | null>(null);
  const [selectedAward, setSelectedAward] = useState<AwardT | null>(null);
  const [selectedClassroomId, setSelectedClassroomId] = useState<string | null>(
    null
  );

  const [studentName, setStudentName] = useState<string | null>(null);
  const [awardReason, setAwardReason] = useState<string | null>(null);
  const [awardValueOverride, setAwardValueOverride] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (open && window.location.pathname.includes('myclassroom')) {
      const parts = window.location.pathname.split('/');
      const index = parts.findIndex(p => p === 'myclassroom');
      const classroomId = parts[index + 1];
      setSelectedClassroomId(classroomId);
    }
  }, [open]);

  const reset = useCallback(() => {
    setOpen(false);
    setSelectedAward(null);
    setSelectedHouseId(null);
  }, [setOpen]);

  const givePoints = useCallback(() => {
    if (selectedHouseId && selectedAward) {
      // take the override into account of they are an admin
      const value = isValidAwardValue(awardValueOverride)
        ? Number(awardValueOverride)
        : selectedAward.value;

      // send the points
      onPointAward({
        classroomId: selectedClassroomId || undefined,
        houseId: selectedHouseId,
        award: { ...selectedAward, value },
        studentName,
        awardReason
      });

      // reset our state
      reset();
    }
  }, [
    awardReason,
    awardValueOverride,
    onPointAward,
    reset,
    selectedAward,
    selectedClassroomId,
    selectedHouseId,
    studentName
  ]);

  const icon =
    isGivingPoints === true ? (
      <Spinner color="white" />
    ) : isGivingPoints === 'success' ? (
      'check'
    ) : (
      'star'
    );

  return (
    <>
      <Fab
        className={classNames(styles.fab, {
          [styles.fabSuccess]: isGivingPoints === 'success'
        })}
        exited={exited}
        icon={icon}
        label={isGivingPoints === true ? 'Sending' : undefined}
        theme={['primaryBg', 'onPrimary']}
        onClick={() => setOpen(true)}
      />

      <Shim open={open} onClick={reset} />

      <Houses
        houses={houses}
        school={school}
        selectedHouseId={selectedHouseId}
        open={open && !selectedHouseId}
        onSelectHouse={setSelectedHouseId}
      />

      <Awards
        open={!!selectedHouseId && !selectedAward}
        school={school}
        selectedAward={selectedAward}
        onSelectAward={setSelectedAward}
      />

      <Points
        open={!!selectedAward}
        isGivingPoints={isGivingPoints}
        award={selectedAward}
        awardValue={
          awardValueOverride !== null
            ? awardValueOverride
            : selectedAward
            ? selectedAward.value
            : null
        }
        house={houses.find(house => selectedHouseId === house.id) || null}
        classrooms={classrooms}
        selectedClassroomId={selectedClassroomId}
        isAdmin={isAdmin}
        studentName={studentName}
        awardReason={awardReason}
        onClassroomChange={setSelectedClassroomId}
        onStudentNameChange={setStudentName}
        onAwardReasonChange={setAwardReason}
        onAwardValueChange={setAwardValueOverride}
        onGivePoints={givePoints}
      />
    </>
  );
}

/** First panel displaying the houses and awards */
function Houses({
  houses,
  selectedHouseId,
  school,
  open,
  onSelectHouse
}: {
  houses: HouseT[];
  school: SchoolT;
  open: boolean;
  selectedHouseId: string | null;
  onSelectHouse: (houseId: string | null) => void;
}) {
  return (
    <Panel open={open}>
      <List className={styles.list} twoLine>
        <ListGroup className={styles.houseList}>
          <ListGroupSubheader className={styles.listHeader}>
            Award Points To...
          </ListGroupSubheader>
          {houses.map(house => {
            const isSelected = selectedHouseId === house.id;
            return (
              <SimpleListItem
                selected={isSelected}
                onClick={() =>
                  onSelectHouse(house.id === selectedHouseId ? null : house.id)
                }
                key={house.name}
                graphic={isSelected ? 'check' : house.image}
                text={house.name}
                secondaryText={
                  school.isBlackout
                    ? '???'
                    : `${house.totalPoints} point${
                        house.totalPoints !== 1 ? 's' : ''
                      }`
                }
                metaIcon={
                  <Button disabled={isSelected}>
                    {isSelected ? 'Selected' : 'Select'}
                  </Button>
                }
              />
            );
          })}
        </ListGroup>
      </List>
    </Panel>
  );
}

/** Second panel displaying the  awards */
function Awards({
  school,
  selectedAward,
  open,
  onSelectAward
}: {
  school: SchoolT;
  open: boolean;
  selectedAward: AwardT | null;
  onSelectAward: (award: AwardT | null) => void;
}) {
  return (
    <Panel open={open}>
      <List className={styles.list} twoLine>
        <ListGroup>
          <ListGroupSubheader className={styles.listHeader}>
            Select Reason...
          </ListGroupSubheader>
          {school.availableAwards.map(award => {
            const isSelected = award === selectedAward;
            return (
              <SimpleListItem
                className={styles.listItem}
                selected={isSelected}
                onClick={() =>
                  onSelectAward(award === selectedAward ? null : award)
                }
                key={award.name}
                graphic={isSelected ? 'check' : 'radio_button_unchecked'}
                text={formatAwardText(award.name, award.value)}
                secondaryText={award.description}
              />
            );
          })}
        </ListGroup>
      </List>
    </Panel>
  );
}

function Points({
  open,
  house,
  classrooms,
  selectedClassroomId,
  award,
  awardReason,
  awardValue,
  studentName,
  isAdmin,
  isGivingPoints,
  onStudentNameChange,
  onAwardReasonChange,
  onAwardValueChange,
  onClassroomChange,
  onGivePoints
}: {
  open: boolean;
  isGivingPoints: boolean | 'success';
  house: HouseT | null;
  classrooms: ClassroomT[];
  selectedClassroomId: string | null;
  award: AwardT | null;
  awardReason: null | string;
  studentName: null | string;
  awardValue: number | string | null;
  isAdmin: boolean | null;
  onStudentNameChange: (name: string) => void;
  onAwardReasonChange: (reason: string) => void;
  onAwardValueChange: (awardValue: number) => void;
  onClassroomChange: (classroomId: string) => void;
  onGivePoints: () => void;
}) {
  return (
    <Panel open={open}>
      {isGivingPoints ? (
        <div
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <div>{isGivingPoints === true ? <Spinner /> : <Checkmark />}</div>
        </div>
      ) : (
        <>
          {!!house && !!award && awardValue !== null && (
            <>
              <div className={styles.panelInner}>
                <div>
                  <Typography
                    use="headline5"
                    tag="div"
                    className={styles.schoolName}
                  >
                    {house.name}
                  </Typography>

                  <Typography use="subtitle1" tag="div">
                    {formatAwardText(award.name, awardValue || '')}
                  </Typography>
                </div>
                <div>
                  <Button
                    disabled={!isValidAwardValue(awardValue)}
                    onClick={onGivePoints}
                    raised
                    icon="star"
                  >
                    Give
                  </Button>
                </div>
              </div>

              <TextField
                label="Student Name (Optional)"
                outlined
                className={styles.panelTextField}
                value={studentName || ''}
                onChange={evt => onStudentNameChange(evt.currentTarget.value)}
              />
              {!!classrooms.length && (
                <Select
                  label="Classroom (Optional)"
                  outlined
                  className={styles.panelTextField}
                  value={selectedClassroomId || ' '}
                  options={classrooms.reduce<{
                    [selectedClassroomId: string]: string;
                  }>(
                    (acc, classroom) => {
                      acc[classroom.id] = classroom.name;
                      return acc;
                    },
                    { ' ': 'None' }
                  )}
                  onChange={evt =>
                    onClassroomChange(evt.currentTarget.value.trim())
                  }
                />
              )}
              <TextField
                label="Award Reason (Optional)"
                outlined
                className={styles.panelTextField}
                textarea
                fullwidth
                value={awardReason || ''}
                onChange={evt => onAwardReasonChange(evt.currentTarget.value)}
              />

              {isAdmin && (
                <TextField
                  onChange={evt =>
                    onAwardValueChange(
                      Number(evt.currentTarget.value.slice(0, AWARD_MAX_LENGTH))
                    )
                  }
                  label="Admin Point Override"
                  outlined
                  type="number"
                  className={styles.panelTextField}
                  value={`${awardValue || ''}`.slice(0, AWARD_MAX_LENGTH)}
                  max="9999"
                  min="1"
                />
              )}
            </>
          )}
        </>
      )}
    </Panel>
  );
}

function Shim({ open, onClick }: { open: boolean; onClick: () => void }) {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.shim, {
        [styles.shimOut]: !open
      })}
    />
  );
}

function Panel({
  children,
  open
}: {
  children: React.ReactNode;
  open: boolean;
}) {
  return (
    <div
      className={classNames(styles.panel, {
        [styles.panelOpen]: open
      })}
    >
      {children}
    </div>
  );
}
