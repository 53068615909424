import { createSelectors } from 'redux-state-branch';

import { ClassroomT, ClassroomStateT, name } from './defs';
import { StateT } from '..';

export const { all, byId, where, meta } = createSelectors<
  ClassroomT,
  ClassroomStateT
>({ name });

export const forUserAtSchool = (
  state: StateT,
  userId: string,
  schoolId: string
) =>
  where(state, {
    callback: classroom =>
      classroom.userId === userId && classroom.schoolId === schoolId
  });
