import React from 'react';

import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarFixedAdjust
} from '@rmwc/top-app-bar';
import classNames from 'classnames';

import styles from './app-bar.module.css';

export function AppBar({
  children,
  divider,
  additionalRow,
  onMenuToggle
}: {
  children?: React.ReactNode;
  divider?: boolean;
  additionalRow?: React.ReactNode;
  onMenuToggle?: () => void;
}) {
  return (
    <>
      <TopAppBar
        className={classNames(styles.appBar, {
          [styles.divider]: divider
        })}
        fixed
      >
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            {!!onMenuToggle && (
              <TopAppBarNavigationIcon icon="notes" onClick={onMenuToggle} />
            )}
            {children}
          </TopAppBarSection>
        </TopAppBarRow>
        {additionalRow}
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </>
  );
}
