import moment from 'moment';
import { PointTransactionT } from '@state/point';

export const name = 'school';

export type TimeframeT = string;
export type TimeRangeT = [number, number];

export type AwardT = {
  name: string;
  description: string;
  value: number;
};

export type SchoolTimeframesT = {
  year: TimeRangeT;
  period: {
    [key: string]: TimeRangeT;
  };
};

export type SchoolT = {
  id: string;
  name: string;
  schoolCode: string;
  availableAwards: AwardT[];
  themePrimary?: null | string;
  themeSecondary?: null | string;
  logo: null | string;
  points: { [key: string]: PointTransactionT };
  pointsFeed: PointTransactionT[];
  isPublic: boolean;
  isBlackout: boolean;
  timeframes: SchoolTimeframesT;
};

export type SchoolsLoadingStateT = {
  givePoints: boolean;
  givePointsSuccess: boolean;
};

export type SchoolStateT = {
  loading: SchoolsLoadingStateT;
  selectedTimeframe: TimeframeT;
  items: {
    [houseId: string]: SchoolT;
  };
};

const schoolTimeframeHelper = (
  time: moment.Moment,
  timeframe: moment.unitOfTime.StartOf
): TimeRangeT => [
  time
    .startOf(timeframe)
    .utc()
    .unix() * 1000,
  time
    .endOf(timeframe)
    .utc()
    .unix() * 1000
];

export const defaultTimeframes = {
  year: schoolTimeframeHelper(moment(), 'year'),
  period: {
    Q1: schoolTimeframeHelper(moment().quarter(1), 'quarter'),
    Q2: schoolTimeframeHelper(moment().quarter(2), 'quarter'),
    Q3: schoolTimeframeHelper(moment().quarter(3), 'quarter'),
    Q4: schoolTimeframeHelper(moment().quarter(4), 'quarter')
  }
};

export const defaultSchool: Partial<SchoolT> = {
  id: '',
  name: 'Untitled School',
  availableAwards: [],
  points: {},
  pointsFeed: [],
  isPublic: false,
  isBlackout: false,
  schoolCode: '',
  logo: null,
  timeframes: defaultTimeframes
};

export const defaultState: SchoolStateT = {
  loading: {
    givePoints: false,
    givePointsSuccess: false
  },
  selectedTimeframe: 'This Week',
  items: {}
};
