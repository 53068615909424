import { stateBranch } from 'redux-state-branch';
import { UserT, UserStateT, name } from './defs';
import { defaultState } from './defs';
import * as selectors from './selectors';
import * as actions from './actions';

export * from './defs';

export const userBranch = stateBranch<UserT, UserStateT>()({
  name,
  selectors,
  actions,
  defaultState
});
