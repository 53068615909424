import { createActions } from 'redux-state-branch';
import { ClassroomT, ClassroomStateT, defaultClassroom, name } from './defs';
import { DispatchT } from '..';
import {
  classroomsFetchForUserAtSchoolQuery,
  classroomCreateQuery,
  classroomRemoveQuery
} from '@state/query';

export const {
  create,
  update,
  replace,
  remove,
  setMeta,
  reset
} = createActions<ClassroomT, ClassroomStateT>({
  name,
  defaultItem: defaultClassroom
});

export const fetchForUserAtSchool = (
  userId: string,
  schoolId: string
) => async (dispatch: DispatchT) => {
  const items = await classroomsFetchForUserAtSchoolQuery({ userId, schoolId });
  return dispatch(create(items));
};

export const newClassroom = ({
  schoolId,
  name,
  callback
}: {
  schoolId: string;
  name: string;
  callback?: (classroom: ClassroomT) => void;
}) => async (dispatch: DispatchT) => {
  const classroomResp = await classroomCreateQuery({ schoolId, name });
  const classroomAction = create(classroomResp);
  callback?.(classroomAction.items[0] as ClassroomT);
  return dispatch(classroomAction);
};

export const removeClassroom = ({
  classroomId
}: {
  classroomId: string;
}) => async (dispatch: DispatchT) => {
  classroomRemoveQuery({ classroomId });
  return dispatch(remove(classroomId));
};
