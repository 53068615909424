import { HouseT } from '@state/house';

import React from 'react';

import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';
import { IOSStatusBar } from '@components/ios-status-bar';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon
} from '@rmwc/top-app-bar';
// @ts-ignore
import { Draggable } from 'gsap/Draggable';
import '@common/vendors/throw-props-plugin';

import styles from './wheel-main.module.css';
import { FireworksParticle } from '@components/particles';
import { Fade } from '@common/transitions';

type WheelMainPropsT = {
  houses: HouseT[];
  onMenuToggle: () => void;
};

type WheelMainStateT = {
  selectedHouseIndex: number | null;
  activeHouseIndex: number;
  showEffect: boolean;
};

export class WheelMain extends React.Component<
  WheelMainPropsT,
  WheelMainStateT
> {
  state = {
    selectedHouseIndex: null,
    activeHouseIndex: 0,
    showEffect: false
  };

  wheelRef: any;

  componentDidMount() {
    const [inst] = Draggable.create(this.wheelRef, {
      type: 'rotation',
      throwProps: true,
      throwResistance: 100,
      minDuration: 2,
      maxDuration: 5,
      onRelease: () => {
        this.setState({ selectedHouseIndex: null });
      },
      onThrowUpdate: () => {
        const activeHouseIndex = this.getHouseIndexFromRotation(inst.rotation);
        if (activeHouseIndex !== this.state.activeHouseIndex) {
          this.setState({ activeHouseIndex });
        }
      },
      onThrowComplete: () => {
        this.setState({
          selectedHouseIndex: this.getHouseIndexFromRotation(inst.rotation),
          showEffect: true
        });

        setTimeout(() => {
          this.setState({
            showEffect: false
          });
        }, 5000);
      }
    });
  }

  getHouseIndexFromRotation(rotation: number): number {
    const numHouses = this.props.houses.length;
    const index =
      Math.abs(Math.floor(rotation / (360 / numHouses))) % numHouses;

    return rotation > 0 && index ? numHouses - index : index;
  }

  render() {
    const { houses, onMenuToggle } = this.props;
    const { activeHouseIndex, selectedHouseIndex, showEffect } = this.state;
    const sliceDegrees = 360 / houses.length;
    const selectedHouse =
      typeof selectedHouseIndex === 'number'
        ? houses[selectedHouseIndex as any]
        : null;

    return (
      <>
        <IOSStatusBar color="#384161" backgroundColor="#384161" />
        <Fade in={!!selectedHouse && showEffect}>
          <FireworksParticle
            style={{ position: 'absolute' }}
            color={selectedHouse ? selectedHouse.color : '#ff00ff'}
          />
        </Fade>
        <div className={styles.wheelMain}>
          <TopAppBar className={styles.topAppBar} fixed>
            <TopAppBarRow>
              <TopAppBarSection alignStart>
                <TopAppBarNavigationIcon icon="notes" onClick={onMenuToggle} />
              </TopAppBarSection>
            </TopAppBarRow>
          </TopAppBar>
          <div className={styles.wheel} ref={el => (this.wheelRef = el)}>
            <div className={styles.wheelInner}>
              {houses.map((house, i) => (
                <WheelSlice
                  key={house.id}
                  house={house}
                  active={i === activeHouseIndex}
                  rotation={sliceDegrees * i}
                />
              ))}
              <Icon icon="stars" className={styles.wheelCenter} />
            </div>
          </div>
          <Icon icon="room" className={styles.arrow} />

          <Typography
            use="headline4"
            className={styles.headline}
            theme="textPrimaryOnDark"
          >
            {!!selectedHouse && selectedHouse.name}
          </Typography>
        </div>
      </>
    );
  }
}

class WheelSlice extends React.Component<{
  rotation: number;
  house: HouseT;
  active: boolean;
}> {
  render() {
    const { rotation, house, active } = this.props;
    const style = {
      backgroundColor: house.color,
      transform: `rotate(${rotation}deg)`
    };
    return (
      <div
        style={style}
        className={[styles.wheelSlice, active && styles.wheelSliceActive]
          .filter(Boolean)
          .join(' ')}
      >
        <img src={house.image || ''} alt={house.name} draggable={false} />
      </div>
    );
  }
}
