import { StateT, DispatchT } from '@state';
import { capitalize } from '@common/string';
import { createActions } from 'redux-state-branch';
import { byId } from './selectors';
import { UserT, UserStateT, name, defaultUser } from './defs';
import {
  userRemoveFromSchoolQuery,
  userToggleAdminPrivilegesQuery,
  userFetchAllForSchoolQuery
} from '@state/query';

export const { update, create, ...rest } = createActions<UserT, UserStateT>({
  name,
  defaultItem: defaultUser
});

export const removeUserFromSchool = (
  schoolId: string,
  schoolCode: string,
  userId: string
) => {
  return async (dispatch: DispatchT, getState: () => StateT) => {
    const user = byId(getState(), { id: userId });

    if (user) {
      dispatch(
        update({
          id: userId,
          schoolCode: user.schoolCode.filter(c => c !== schoolCode)
        })
      );
    }

    userRemoveFromSchoolQuery({ schoolId, userId });
  };
};

export const toggleAdminPrivileges = (
  schoolId: string,
  userId: string,
  isAdmin: boolean
) => {
  return async (dispatch: DispatchT) => {
    const user = await userToggleAdminPrivilegesQuery({
      schoolId,
      userId,
      isAdmin
    });
    // TODO, user response is not typesafe
    dispatch(update({ id: userId, admin: user.app_metadata.admin }));
  };
};

export const fetchUsersForSchool = (schoolId: string) => {
  return async (dispatch: DispatchT) => {
    const users = await userFetchAllForSchoolQuery({ schoolId });

    dispatch(
      create(
        users.map(u => {
          const firstName =
            u.user_metadata.firstName && capitalize(u.user_metadata.firstName);
          const lastName =
            u.user_metadata.lastName && capitalize(u.user_metadata.lastName);
          return {
            id: u.user_id,
            email: u.email,
            admin: u.app_metadata.admin || [],
            avatar: u.avatar,
            firstName,
            lastName,
            name: [firstName, lastName].filter(Boolean).join(' '),
            lastLogin: u.last_login,
            schoolCode: Array.isArray(u.app_metadata.schoolCode)
              ? u.app_metadata.schoolCode
              : [u.app_metadata.schoolCode]
          };
        })
      )
    );
  };
};
