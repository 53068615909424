import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { auth } from '@common/auth';

export const PrivateRoute = ({
  component: Component,
  render,
  ...rest
}: {
  component?: React.ComponentType<any>;
  render?: Function;
} & RouteProps) => {
  return (
    <Route
      {...rest}
      render={matchProps => {
        if (!auth.isAuthenticated()) {
          auth.login();
          return null;
        }

        if (render) {
          return render(matchProps);
        }

        if (Component) {
          return <Component {...matchProps} />;
        }

        return null;
      }}
    />
  );
};
