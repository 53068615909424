import { DispatchT } from '@state';
import { createActions } from 'redux-state-branch';
import moment from 'moment';
import { HouseT } from '@state/house';
import { PointTransactionT } from '@state/point';
import { exportCSV } from '@common/csv';
import {
  CHANGE_SELECTED_TIMEFRAME,
  UPDATE_POINT_TRANSACTIONS,
  FETCH_GIVE_POINTS,
  FETCH_GIVE_POINTS_COMPLETE,
} from './constants';
import {
  SchoolT,
  SchoolStateT,
  TimeRangeT,
  TimeframeT,
  AwardT,
  name,
  defaultSchool,
} from './defs';

import {
  schoolToggleBlackoutQuery,
  schoolResetPointsQuery,
  schoolFetchByIdWatcher,
  schoolFetchAllQuery,
  schoolGivePointsQuery,
  schoolUpdateAwardsQuery,
  pointsFetchQuery,
} from '@state/query';

export const { create, update, noop, ...rest } = createActions<
  SchoolT,
  SchoolStateT
>({
  name,
  defaultItem: defaultSchool,
});

export const exportPointTransactionCSV = (
  school: SchoolT,
  houses: HouseT[],
  timeRange: TimeRangeT,
  pointTransactions: PointTransactionT[]
) => {
  const houseMap = houses.reduce<{ [key: string]: HouseT }>((acc, house) => {
    acc[house.id] = house;
    return acc;
  }, {});

  const data = pointTransactions.reduce(
    (acc, p) => {
      acc.push([
        houseMap[p.houseId].name,
        p.awardName,
        String(p.awardValue),
        p.teacherName,
        p.studentName,
        moment(p.createdAt).local().format('YYYY/MM/DD HH:MM:SS'),
      ]);
      return acc;
    },
    [['House', 'Award', 'Points', 'Teacher', 'Student', 'Created At']]
  );

  const [startTime, endTime] = timeRange;

  const dateString = [
    moment(startTime).local().format('l'),

    moment(endTime).local().format('l'),
  ].join(' - ');

  const filename = `${school.name} Leader-Live Data (${dateString}).csv`.replace(
    /\//g,
    '-'
  );

  exportCSV(data, filename);

  return {
    type: '',
  };
};

export const changeSelectedTimeframe = (selectedTimeframe: TimeframeT) => {
  return {
    type: CHANGE_SELECTED_TIMEFRAME,
    meta: {
      selectedTimeframe,
    },
  };
};

/** Fetch points transactions for a school */
export const fetchPointTransactions = (
  schoolId: string,
  timeRange: TimeRangeT
) => {
  return async (dispatch: DispatchT) => {
    // TODO, these seems like  duplicate behavior of the points branch

    const points = await pointsFetchQuery({
      schoolId,
      timeRange,
    });

    dispatch({
      type: UPDATE_POINT_TRANSACTIONS,
      items: [{ id: schoolId, points }],
    });
  };
};

/** Fetch a school by its id */
export const fetchById = (schoolId: string) => {
  return async (dispatch: DispatchT) => {
    // TODO, this watcher never provides a way to cancel
    schoolFetchByIdWatcher({
      id: schoolId,
      onSchool: (school) => {
        dispatch(create(school));
      },
    });
  };
};

/** Fetch all schools */
export const fetchAll = () => {
  return async (dispatch: DispatchT) => {
    const schools = await schoolFetchAllQuery();
    dispatch(update(schools));
  };
};

/** Awards points to a specific house */
export const givePoints = ({
  houseId,
  schoolId,
  classroomId,
  award: { name: awardName, value: awardValue },
  studentName,
  awardReason,
}: {
  schoolId: string;
  houseId: string;
  classroomId?: string;
  award: Partial<AwardT>;
  studentName: null | string;
  awardReason: null | string;
}) => {
  return async (dispatch: DispatchT) => {
    dispatch({
      type: FETCH_GIVE_POINTS,
    });

    const done = (isSuccess?: boolean) =>
      dispatch({
        type: FETCH_GIVE_POINTS_COMPLETE,
        data: {
          isSuccess,
        },
      });

    try {
      await schoolGivePointsQuery({
        schoolId,
        houseId,
        classroomId,
        awardName,
        awardValue,
        studentName,
        awardReason,
      });
      done(true);
      setTimeout(done, 1500);
    } catch (err) {
      done();
    }
  };
};

/** Allows admins to update the awards list  */
export const updateAvailableAwards = (
  schoolId: string,
  availableAwards: AwardT[]
) => {
  return async (dispatch: DispatchT) => {
    schoolUpdateAwardsQuery({ schoolId, availableAwards });

    dispatch(
      update([
        {
          id: schoolId,
          availableAwards,
        },
      ])
    );
  };
};

/** Reset points for leaderboard */
export const resetPointsForSchool = (schoolId: string) => {
  return async (dispatch: DispatchT) => {
    await schoolResetPointsQuery({ schoolId });

    dispatch(noop());
  };
};

export const toggleBlackout = (schoolId: string, isBlackout: boolean) => {
  return async (dispatch: DispatchT) => {
    schoolToggleBlackoutQuery({ schoolId, isBlackout });
    dispatch(update({ id: schoolId, isBlackout }));
  };
};
