import React, { useEffect, useCallback } from 'react';
import { StateT } from '@state';
import { AwardT, SchoolT } from '@state/school';
import { HouseT } from '@state/house';
import { useDispatch, useSelector } from 'react-redux';
import { schoolBranch } from '@state/school';
import { currentUserBranch } from '@state/current-user';
import { classroomBranch } from '@state/classroom';
import { GivePointsMain } from './give-points-main';

export function GivePointsContainer({
  houses,
  school,
  open,
  exited,
  setOpen
}: {
  houses: HouseT[];
  school: SchoolT;
  open: boolean;
  exited: boolean;
  setOpen: (open: boolean) => void;
}) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: StateT) =>
    currentUserBranch.select.get(state)
  );

  const isAdmin = useSelector((state: StateT) =>
    currentUserBranch.select.isAdmin(state)
  );

  const loading = useSelector((state: StateT) =>
    schoolBranch.select.loading(state)
  );

  const classrooms = useSelector((state: StateT) =>
    classroomBranch.select.forUserAtSchool(state, currentUser.id, school.id)
  );

  const dispatchFetchClassroomsForUserAtSchool = useCallback(
    (userId: string) => {
      dispatch(classroomBranch.action.fetchForUserAtSchool(userId, school.id));
    },
    [dispatch, school.id]
  );

  const dispatchAwardPoints = useCallback(
    ({
      houseId,
      award,
      studentName,
      awardReason,
      classroomId
    }: {
      houseId: string;
      classroomId?: string;
      award: AwardT;
      studentName: null | string;
      awardReason: null | string;
    }) =>
      houseId &&
      school.id &&
      dispatch(
        schoolBranch.action.givePoints({
          classroomId,
          houseId,
          schoolId: school.id,
          award,
          studentName,
          awardReason
        })
      ),
    [dispatch, school.id]
  );

  useEffect(() => {
    dispatchFetchClassroomsForUserAtSchool(currentUser.id);
  }, [dispatchFetchClassroomsForUserAtSchool, currentUser.id, school.id]);

  return (
    <GivePointsMain
      open={open}
      setOpen={setOpen}
      isAdmin={isAdmin}
      isGivingPoints={
        loading.givePointsSuccess ? 'success' : loading.givePoints
      }
      school={school}
      houses={houses}
      classrooms={classrooms}
      exited={exited}
      onPointAward={dispatchAwardPoints}
    />
  );
}
