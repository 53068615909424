import { StateT } from '@state';

import ReactGA from 'react-ga';
import { auth } from '@common/auth';
import jwt from 'jsonwebtoken';
import { env } from '@common/env';
import { AnyAction } from 'redux';
import * as constants from './constants';
import { CurrentUserStateT, defaultState, name } from './defs';

export * from './defs';

export const currentUserBranch = {
  name,
  constant: constants,
  select: {
    get: (state: StateT) => state.currentUser,
    isAuthenticated: () => auth.isAuthenticated(),
    isAdmin: (state: StateT) => state.currentUser.isAdmin,
    hasAccessToSchool: (state: StateT, schoolCode: string) =>
      state.currentUser.schoolCode.includes(schoolCode)
  },
  action: {
    login: () => {
      auth.login();
      return { type: currentUserBranch.constant.LOGIN };
    },
    logout: () => {
      auth.logout();
      return { type: currentUserBranch.constant.LOGOUT };
    },
    reset: () => ({
      type: currentUserBranch.constant.RESET
    }),
    setUser: (idToken: string) => {
      const payload: any = jwt.verify(
        idToken,
        String(env('AUTH0_PUBLIC_JWT_SIGNING_KEY'))
      );

      const userMeta = payload['https://leader.live/user_metadata'] || {};
      const appMeta = payload['https://leader.live/app_metadata'] || {};

      // payload.sub is the user ID
      // if defined, init user tracking
      ReactGA.set({ userId: payload.sub });

      return {
        type: currentUserBranch.constant.SET_USER,
        meta: {
          id: payload.sub,
          firstName: userMeta.firstName || null,
          lastName: userMeta.lastName || null,
          isAdmin: !!appMeta.admin,
          schoolCode: Array.isArray(appMeta.schoolCode)
            ? appMeta.schoolCode
            : [appMeta.schoolCode]
        }
      };
    }
  },
  reducer: (state: CurrentUserStateT = defaultState, action: AnyAction) => {
    switch (action.type) {
      case currentUserBranch.constant.SET_USER:
        return action.meta;
      case currentUserBranch.constant.RESET:
        return defaultState;
      default:
        return state;
    }
  }
};
