import React from 'react';

import styles from './spinner_.module.css';

export const Spinner = ({ color }: { color?: string }) => (
  <div
    className={styles.spinner}
    style={
      color
        ? ({ '--mdc-theme-primary': color } as React.CSSProperties)
        : undefined
    }
  >
    <div className={styles['sk-cube-grid']}>
      <div className={[styles['sk-cube'], styles['sk-cube1']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube2']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube3']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube4']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube5']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube6']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube7']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube8']].join(' ')} />
      <div className={[styles['sk-cube'], styles['sk-cube9']].join(' ')} />
    </div>
  </div>
);
