import React, { useState, useCallback } from 'react';
import { SchoolT } from '@state/school';
import { HouseT } from '@state/house';

import { Location } from 'history';
import { Switch, Route, Link, match as Match } from 'react-router-dom';
import { CircularProgress } from '@rmwc/circular-progress';
import { Drawer, DrawerHeader, DrawerContent } from '@rmwc/drawer';
import { Button } from '@rmwc/button';
import { ListItem, ListItemText, ListItemGraphic } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { LeaderboardContainer } from '@views/leaderboard/leaderboard-container';

import { PrivateRoute } from '@components/private-route';
import { AccessDenied } from '@components/access-denied';
import styles from './school-main.module.css';
import WheelContainer from '@views/wheel/wheel-container';
import { feedback } from '@common/feedback';
import { ClassroomContainer } from '@views/classroom';
import { GivePointsContainer } from '@views/give-points/give-points-container';

const AdminContainer = React.lazy(() => import('@views/admin/admin-container'));

//const WheelContainer = React.lazy(() => import('views/wheel/WheelContainer'));

const SuspenseLoading = ({ children }: { children: React.ReactNode }) => (
  <React.Suspense
    fallback={
      <CircularProgress
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
        size="xlarge"
      />
    }
  >
    {children}
  </React.Suspense>
);

export function SchoolMain({
  school,
  houses,
  location,
  match,
  hasEducatorAccess,
  isAdmin,
  isAuthenticated,
  onLoginClick,
  onLogoutClick
}: {
  school: SchoolT;
  houses: HouseT[];
  match: Match;
  location: Location;
  isAdmin: boolean;
  isAuthenticated: boolean;
  hasEducatorAccess: boolean;
  onLoginClick: () => void;
  onLogoutClick: () => void;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [givePointsOpen, setGivePointsOpen] = useState(false);

  const toggleMenu = useCallback(
    (bool: boolean = !isMenuOpen) => {
      setIsMenuOpen(bool);
    },
    [isMenuOpen]
  );

  const menuItems = [
    {
      name: 'Leaderboard',
      icon: 'bar_chart',
      to: `${match.url}`,
      show: true,
      activated: location.pathname.endsWith(match.url)
    },
    {
      name: 'Give Points',
      icon: 'stars',
      to: () => {
        setGivePointsOpen(true);
      },
      show: isAuthenticated
    },
    {
      name: 'My Classroom',
      icon: 'school',
      to: `${match.url}/educators/myclassroom`,
      show: isAuthenticated,
      activated: location.pathname.includes('myclassroom')
    },
    {
      name: 'Wheel',
      icon: 'donut_small',
      to: `${match.url}/wheel`,
      show: isAuthenticated,
      activated: location.pathname.includes('wheel')
    },
    {
      name: 'Admins',
      icon: 'security',
      to: `${match.url}/educators/admin`,
      show: isAuthenticated && isAdmin && hasEducatorAccess,
      activated: location.pathname.includes('admin')
    },
    {
      name: 'Feedback',
      icon: 'feedback',
      to: () => feedback.open(),
      show: isAuthenticated
    }
  ].filter(item => item.show);

  const givePointsExited = location.pathname.includes('admin');

  return (
    <>
      <Drawer
        className={styles.drawer}
        modal
        open={isMenuOpen}
        onClose={() => toggleMenu(false)}
      >
        <DrawerHeader
          theme={['secondaryBg', 'onSecondary']}
          className={styles.drawerHeader}
        >
          <Button
            className={styles.backButton}
            icon="arrow_back"
            label="Home"
            tag={Link}
            {...{ to: '/' }}
          />

          <div>
            <Typography use="subtitle2" tag="div" style={{ opacity: 0.7 }}>
              Leader.Live
            </Typography>
            <Typography use="headline6">{school.name}</Typography>
          </div>
        </DrawerHeader>
        <DrawerContent style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {menuItems.map(menuItem => (
              <ListItem
                activated={!!menuItem.activated}
                key={menuItem.name}
                tag={typeof menuItem.to === 'string' ? Link : 'div'}
                {...(typeof menuItem.to === 'string'
                  ? { to: menuItem.to }
                  : {})}
                onClick={() => {
                  if (typeof menuItem.to === 'function') {
                    menuItem.to();
                  }
                  toggleMenu(false);
                }}
              >
                <ListItemGraphic icon={menuItem.icon} />
                <ListItemText>{menuItem.name}</ListItemText>
              </ListItem>
            ))}
          </div>
          <div style={{ flex: 1 }} />
          {isAuthenticated ? (
            <Button onClick={onLogoutClick}>Logout</Button>
          ) : (
            <Button onClick={onLoginClick}>Login</Button>
          )}
          <div style={{ minHeight: '3rem' }} />
        </DrawerContent>
      </Drawer>
      <Switch>
        {!hasEducatorAccess &&
          ['/:schoolId/educators', '/:schoolId/wheel'].map(path => (
            <PrivateRoute
              key={path}
              path={path}
              render={() => (
                <AccessDenied
                  message="Your account does not have educator privileges for this school."
                  linkTo={`/${school.id}`}
                  linkText="Return to Leaderboard"
                />
              )}
            />
          ))}
        <PrivateRoute
          path="/:schoolId/educators/myclassroom/:classroomId?/:timeframe?"
          render={({ match }) =>
            hasEducatorAccess ? (
              <SuspenseLoading>
                <ClassroomContainer
                  houses={houses}
                  toggleMenu={toggleMenu}
                  school={school}
                  classroomId={match.params.classroomId}
                  timeframe={match.params.timeframe}
                />
              </SuspenseLoading>
            ) : (
              <AccessDenied
                message="Your account does not have admin privileges for this school."
                linkTo={`/${school.id}`}
                linkText="Return to Leaderboard"
              />
            )
          }
        />
        <PrivateRoute
          path="/:schoolId/educators/admin"
          render={({ ...props }) =>
            isAdmin ? (
              <SuspenseLoading>
                <AdminContainer
                  school={school}
                  houses={houses}
                  toggleMenu={toggleMenu}
                  {...props}
                />
              </SuspenseLoading>
            ) : (
              <AccessDenied
                message="Your account does not have admin privileges for this school."
                linkTo={`/${school.id}`}
                linkText="Return to Leaderboard"
              />
            )
          }
        />
        <PrivateRoute
          path="/:schoolId/wheel"
          render={({ ...props }) => (
            <WheelContainer
              houses={houses}
              toggleMenu={toggleMenu}
              {...props}
            />
          )}
        />
        <Route
          path="/:schoolId"
          exact
          render={({ ...props }) => (
            <LeaderboardContainer
              school={school}
              houses={houses}
              toggleMenu={toggleMenu}
              {...props}
            />
          )}
        />
      </Switch>
      {hasEducatorAccess && (
        <GivePointsContainer
          school={school}
          houses={houses}
          open={givePointsOpen}
          setOpen={setGivePointsOpen}
          exited={givePointsExited}
        />
      )}
    </>
  );
}
