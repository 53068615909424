export type TimeframeT = string;
export type TimeRangeT = [number, number];

export const name = 'point';

export enum ClassroomTimeRangeOptions {
  'today' = 'today',
  'week' = 'week',
  'all' = 'all'
}

export type PointTransactionT = {
  id: string;
  awardName: string;
  awardValue: number;
  awardReason: string;
  createdAt: number;
  houseId: string;
  studentName: string;
  teacherName: string;
  userId?: string;
  classroomId?: string;
  schoolId: string;
};

export type PointsStateT = {
  items: { [id: string]: PointTransactionT };
};

export const defaultPointTransaction: Partial<PointTransactionT> = {
  id: '',
  awardName: 'Untitled Award',
  awardValue: 0,
  awardReason: '',
  createdAt: Date.now(),
  houseId: '',
  studentName: '',
  teacherName: ''
};
