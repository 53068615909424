import { StateT } from '@state';
import { match } from 'react-router-dom';
import { HouseT } from '@state/house';
import { SchoolT } from '@state/school';

import React from 'react';
import { useSelector } from 'react-redux';
import { LeaderboardMain } from './leaderboard-main';
import { pointBranch } from '@state/point';

export function LeaderboardContainer({
  houses,
  school,
  toggleMenu
}: {
  match: match;
  school: SchoolT;
  houses: HouseT[];
  toggleMenu: () => void;
}) {
  const pointsFeed = useSelector((state: StateT) =>
    pointBranch.select.schoolFeed(state, school.id)
  );

  const pointNotification = useSelector((state: StateT) =>
    pointsFeed.length ? pointsFeed[0] : undefined
  );

  return (
    <LeaderboardMain
      pointsFeed={pointsFeed}
      pointNotification={pointNotification}
      houses={houses}
      school={school}
      onMenuToggle={toggleMenu}
    />
  );
}
