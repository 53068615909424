import React from 'react';
import styles from './view.module.css';
import { Typography } from '@rmwc/typography';

export function View({
  children,
  title,
  className
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={[styles.view, className].filter(Boolean).join(' ')}>
      {title && (
        <div className={styles.title}>
          <Typography tag="h1" use="headline5">
            {title}
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}
