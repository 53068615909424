import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './transitions.module.css';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export function Fade({ ...rest }: Partial<CSSTransitionProps>) {
  return (
    <CSSTransition
      timeout={500}
      classNames={{
        appear: styles.fadeEnter,
        appearActive: styles.fadeEnterActive,
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        enterDone: styles.fadeEnterActive,
        exit: styles.fadeExit,
        exitActive: styles.fadeExitActive,
        exitDone: styles.fadeExitActive
      }}
      appear
      unmountOnExit
      mountOnEnter
      {...rest}
    />
  );
}
