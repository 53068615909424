import { match } from 'react-router';
import { HouseT } from '@state/house';

import React from 'react';
import { WheelMain } from './wheel-main';

export function WheelContainer({
  houses,
  toggleMenu
}: {
  match: match;
  houses: HouseT[];
  toggleMenu: () => void;
}) {
  return <WheelMain onMenuToggle={toggleMenu} houses={houses} />;
}

export default WheelContainer;
