import moment from 'moment';
import { StateT } from '@state';
import { createSelectors } from 'redux-state-branch';
import {
  PointTransactionT,
  PointsStateT,
  name,
  ClassroomTimeRangeOptions
} from './defs';

export const { byId, where, meta, all } = createSelectors<
  PointTransactionT,
  PointsStateT
>({ name });

export const forClassroom = (
  state: StateT,
  {
    classroomId,
    timeframe
  }: { classroomId?: string; timeframe?: ClassroomTimeRangeOptions }
) => {
  let min = -Infinity;
  let max = Infinity;
  let now = moment();

  switch (timeframe) {
    case ClassroomTimeRangeOptions.today:
      min = now.startOf('day').unix() * 1000;
      max = now.endOf('day').unix() * 1000;
      break;
    case ClassroomTimeRangeOptions.week:
      min = now.startOf('week').unix() * 1000;
      max = now.endOf('week').unix() * 1000;
      break;
    case ClassroomTimeRangeOptions.all:
    default:
      break;
  }

  return where(state, {
    callback: point =>
      point.classroomId === classroomId &&
      point.createdAt > min &&
      point.createdAt < max
  });
};

export const forSchool = (state: StateT, schoolId?: string) =>
  where(state, { callback: point => point.schoolId === schoolId }).sort(
    (a, b) => a.createdAt - b.createdAt
  );

export const schoolFeed = (state: StateT, schoolId?: string) =>
  [...forSchool(state, schoolId)].reverse();

/** A mapping of houseIds to total points. */
export const classroomHousesPointsMap = (
  state: StateT,
  {
    classroomId,
    timeframe
  }: { classroomId?: string; timeframe?: ClassroomTimeRangeOptions }
) =>
  forClassroom(state, { classroomId, timeframe }).reduce<{
    [houseId: string]: number;
  }>((acc, point) => {
    acc[point.houseId] = acc[point.houseId] || 0;
    acc[point.houseId] += point.awardValue;
    return acc;
  }, {});
