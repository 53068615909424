export const name = 'currentUser';

export type CurrentUserStateT = {
  firstName: null | string;
  lastName: null | string;
  isAdmin: boolean;
  schoolCode: string[];
  id: string;
};

export const defaultState: CurrentUserStateT = {
  isAdmin: false,
  schoolCode: [],
  firstName: null,
  lastName: null,
  id: ''
};
