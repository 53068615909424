// The download function takes a CSV string, the filename and mimeType as parameters
// Scroll/look down at the bottom of this snippet to see how download is called
export const download = (
  content: string,
  fileName: string,
  mimeType: string
) => {
  var a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  if (window.navigator.msSaveBlob) {
    // IE10
    window.navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType
      }),
      fileName
    );
  } else if (URL && 'download' in a) {
    //html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType
      })
    );
    a.setAttribute('download', fileName);
    document.body && document.body.appendChild(a);
    a.click();
    document.body && document.body.removeChild(a);
  } else {
    window.location.href = `data:${mimeType},` + encodeURIComponent(content); // only this mime type is supported
  }
};
