//@flow

import auth0, { Auth0DecodedHash } from 'auth0-js';
import { history } from '@common/history';

const isIframe = window.self !== window.top;

const AUTH_CONFIG = {
  domain: 'auth.leader.live',
  clientId: 'QdqriqtKOJBSz8QqSd3ztiZURdE3fGR2',
  callbackUrl: `${window.location.origin}/auth-callback`
};

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: `https://auth.leader.live/browser/`,
    responseType: 'token id_token',
    scope: 'openid profile email user_metadata app_metadata'
  });

  _onLogin: (idToken: string) => void = () => {};
  onLogout: () => void = () => {};

  set onLogin(func: (idToken: string) => void) {
    this._onLogin = func;

    if (this.isAuthenticated()) {
      func(localStorage.getItem('id_token') || '');
    }
  }

  get onLogin() {
    return this._onLogin;
  }

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this._callOnLoginHandler = this._callOnLoginHandler.bind(this);
  }

  login() {
    if (!isIframe) {
      window.localStorage.setItem('authRedirect', window.location.pathname);
      history.push('/login');
    }
  }

  listenForLogin() {
    if (!isIframe) {
      window.addEventListener('storage', this._callOnLoginHandler);
    }
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace('/');
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult: Auth0DecodedHash | null) {
    if (!authResult) {
      return;
    }

    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn
        ? authResult.expiresIn * 1000 + new Date().getTime()
        : 0
    );
    localStorage.setItem('access_token', authResult.accessToken || '');
    localStorage.setItem('id_token', authResult.idToken || '');
    localStorage.setItem('expires_at', expiresAt);
  }

  logout() {
    this.onLogout();
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
    history.replace('/');
  }

  isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(
      // @ts-ignore
      window.localStorage.getItem('expires_at')
    );
    return new Date().getTime() < expiresAt;
  }

  buildAuthUrl() {
    // @ts-ignore
    const params = this.auth0.transactionManager.process(
      // @ts-ignore
      this.auth0.baseOptions
    );

    return this.auth0.client.buildAuthorizeUrl(params);
  }

  _callOnLoginHandler() {
    if (this.isAuthenticated() && !isIframe) {
      const token = localStorage.getItem('id_token');
      token && this._onLogin(token);
      window.removeEventListener('storage', this._callOnLoginHandler);

      setTimeout(() => {
        history.replace(window.localStorage.getItem('authRedirect') || '/');
        window.localStorage.removeItem('authRedirect');
      });
    }
  }
}

export const auth = new Auth();
