import { hot } from 'react-hot-loader/root';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { SchoolContainer } from '@views/school/school-container';
import { HomeContainer } from '@views/home/home-container';
import { AuthCallback } from '@views/auth/auth-callback';
import { Login } from '@views/auth/login';
import { DownloadCallback } from '@views/download/download-callback';
import { history } from '@common/history';
import { currentUserBranch } from '@state/current-user';
import { auth } from '@common/auth';
import { SnackbarQueue } from '@rmwc/snackbar';
import { DialogQueue } from '@rmwc/dialog';
import { messages } from '@common/notifications';
import { dialogs } from '@common/dialogs';
import { IOSStatusBar } from '@components/ios-status-bar';

export const App = hot(function App() {
  const dispatch = useDispatch();

  const dispatchResetAuth = useCallback(
    () => dispatch(currentUserBranch.action.reset()),
    [dispatch]
  );

  const dispatchSetUser = useCallback(
    (idToken: string) => dispatch(currentUserBranch.action.setUser(idToken)),
    [dispatch]
  );

  useEffect(() => {
    auth.onLogin = dispatchSetUser;
    auth.onLogout = dispatchResetAuth;
  }, [dispatchSetUser, dispatchResetAuth]);

  return (
    <Router history={history}>
      <>
        <IOSStatusBar backgroundColor="white" color="white" />
        <Switch>
          <Route path="/download" component={DownloadCallback} />
          <Route path="/auth-callback" component={AuthCallback} />
          <Route path="/login" exact component={Login} />
          <Route path="/:schoolId" component={SchoolContainer} />
          <Route path="/" exact component={HomeContainer} />
        </Switch>
        <SnackbarQueue messages={messages} />
        <DialogQueue dialogs={dialogs} />
      </>
    </Router>
  );
});
