import React from 'react';
import { HouseT } from '@state/house';

import styles from './leaderboard.module.css';
import { Typography } from '@rmwc/typography';

export function Leaderboard({
  houses,
  isBlackout,
  pointsMap,
  children
}: {
  houses: HouseT[];
  /** Black out this leaderboard */
  isBlackout?: boolean;
  /** An optional map of houseId to points to use instead of house.totalPoints */
  pointsMap?: { [houseId: string]: number };
  children?: React.ReactNode;
}) {
  return (
    <div className={styles.leaderboardMain}>
      <div className={styles.grid}>
        {houses.map(house => (
          <House
            key={house.name}
            house={house}
            isBlackout={!!isBlackout}
            points={pointsMap ? pointsMap[house.id] : house.totalPoints}
          />
        ))}
      </div>
      {children}
    </div>
  );
}

function House({
  house,
  points = 0,
  isBlackout
}: {
  house: HouseT;
  points: number;
  isBlackout: boolean;
}) {
  const color = isBlackout ? '#222225' : house.color;

  return (
    <div className={styles.house}>
      <div
        className={styles.houseBg}
        style={{ backgroundColor: color, color }}
      />
      <div className={styles.houseInner}>
        {house.image && (
          <div
            className={styles.houseImg}
            style={{ backgroundImage: `url(${house.image})` }}
          />
        )}
        <div className={styles.houseText}>
          <Typography use="headline3" tag="h3" className={styles.housePoints}>
            {isBlackout ? '???' : points.toLocaleString()}
          </Typography>
          <Typography use="headline5" tag="h4" className={styles.houseName}>
            {house.name}
          </Typography>
        </div>
      </div>
    </div>
  );
}
