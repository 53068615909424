import { stateBranch } from 'redux-state-branch';

import * as actions from './actions';
import * as selectors from './selectors';
import { PointTransactionT, PointsStateT, name } from './defs';

export * from './defs';

export const pointBranch = stateBranch<PointTransactionT, PointsStateT>()({
  name,
  actions,
  selectors
});
