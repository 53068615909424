import React, { useEffect, useCallback } from 'react';
import { StateT } from '@state';
import { schoolBranch } from '@state/school';
import { HomeMain } from './home-main';
import { useDispatch, useSelector } from 'react-redux';

export function HomeContainer() {
  const dispatch = useDispatch();

  const schools = useSelector((state: StateT) =>
    schoolBranch.select.publiclyVisible(state)
  );

  const dispatchFetchSchools = useCallback(
    () => dispatch(schoolBranch.action.fetchAll()),
    [dispatch]
  );

  useEffect(() => {
    dispatchFetchSchools();
  }, [dispatchFetchSchools]);

  return <HomeMain schools={schools} />;
}
