import { StateT } from '@state';
import { match as Match } from 'react-router-dom';

import React, { useEffect, useCallback } from 'react';
import { houseBranch } from '@state/house';
import { schoolBranch } from '@state/school';
import { SchoolMain } from './school-main';
import { currentUserBranch } from '@state/current-user';
import { pointBranch } from '@state/point';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

export function SchoolContainer({
  match
}: {
  match: Match<{ schoolId: string }>;
}) {
  const location = useLocation();

  const dispatch = useDispatch();

  const school = useSelector((state: StateT) =>
    schoolBranch.select.byId(state, {
      id: match.params.schoolId
    })
  );

  const isAuthenticated = currentUserBranch.select.isAuthenticated();

  const isAdmin = useSelector((state: StateT) =>
    currentUserBranch.select.isAdmin(state)
  );

  const hasEducatorAccess = useSelector((state: StateT) =>
    currentUserBranch.select.hasAccessToSchool(
      state,
      school ? school.schoolCode : ''
    )
  );

  const houses = useSelector((state: StateT) =>
    houseBranch.select.bySchool(state, {
      schoolId: match.params.schoolId
    })
  );

  const dispatchLogin = useCallback(
    () => dispatch(currentUserBranch.action.login()),
    [dispatch]
  );

  const dispatchLogout = useCallback(
    () => dispatch(currentUserBranch.action.logout()),
    [dispatch]
  );

  const dispatchFetchSchool = useCallback(
    () =>
      match.params.schoolId &&
      dispatch(schoolBranch.action.fetchById(match.params.schoolId)),
    [dispatch, match.params.schoolId]
  );

  const dispatchWatchHouses = useCallback(() => {
    if (match.params.schoolId) {
      return dispatch(houseBranch.action.watchHouses(match.params.schoolId));
    }

    return () => {};
  }, [dispatch, match.params.schoolId]);

  const dispatchWatchPointTransactions = useCallback(() => {
    if (match.params.schoolId) {
      return dispatch(
        pointBranch.action.watchPointTransactions(match.params.schoolId)
      );
    }

    return () => {};
  }, [dispatch, match.params.schoolId]);

  useEffect(() => {
    dispatchFetchSchool();
    const unwatchHouses = dispatchWatchHouses();
    const unwatchPointTransactions = dispatchWatchPointTransactions();
    return () => {
      unwatchHouses(dispatch);
      unwatchPointTransactions(dispatch);
    };
  }, [
    dispatch,
    dispatchFetchSchool,
    dispatchWatchHouses,
    dispatchWatchPointTransactions,
    match.params.schoolId
  ]);

  return school && houses && houses.length ? (
    <SchoolMain
      houses={houses}
      school={school}
      match={match}
      isAdmin={isAdmin}
      location={location}
      hasEducatorAccess={hasEducatorAccess}
      isAuthenticated={isAuthenticated}
      onLoginClick={dispatchLogin}
      onLogoutClick={dispatchLogout}
    />
  ) : null;
}
