import { HouseT } from '@state/house';
import { SchoolT } from '@state/school';

import React, { useRef, useState, useEffect } from 'react';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  TopAppBarFixedAdjust,
  TopAppBarActionItem
} from '@rmwc/top-app-bar';

import styles from './leaderboard-main.module.css';
import { notify } from '@common/notifications';
import { Drawer, DrawerContent, DrawerTitle, DrawerHeader } from '@rmwc/drawer';
import { Typography } from '@rmwc/typography';
import { FireworksParticle } from '@components/particles';
import { Fade } from '@common/transitions';
import { Leaderboard } from '@components/leaderboard';
import { PointTransactionT } from '@state/point';
import moment from 'moment';

import { View } from '@components/view';

const formatNotificationMessage = (
  houseName: string,
  { awardName, awardValue, awardReason, studentName }: PointTransactionT
) => {
  const parts = [];

  if (studentName) {
    parts.push(studentName);
    parts.push('for');
  }

  parts.push(houseName + '!');

  const bodyParts = [awardName];

  if (awardReason) {
    bodyParts.push(awardReason);
  }

  return {
    title: parts.join(' '),
    body: bodyParts.join(' • ')
  };
};

export function LeaderboardMain({
  houses,
  school,
  pointNotification,
  onMenuToggle,
  pointsFeed
}: {
  pointsFeed: PointTransactionT[];
  pointNotification?: PointTransactionT;
  houses: HouseT[];
  school: SchoolT;
  onMenuToggle: () => void;
}) {
  const [showFireworks, setShowFireworks] = useState(false);
  const [pointsFeedOpen, setPointsFeedOpen] = useState(false);
  const fireworksTimerId = useRef<number | null>(null);
  const prevIsBlack = useRef(school.isBlackout);

  // Handle notifications
  useEffect(() => {
    if (!pointNotification) return;
    const house = houses.find(h => h.id === pointNotification.houseId);

    if (house) {
      const { title, body } = formatNotificationMessage(
        house.name,
        pointNotification
      );
      notify({
        icon: (
          <div className={styles.notificationNumber}>
            +{pointNotification.awardValue}
          </div>
        ),
        title: <b>{title}</b>,
        body,
        timeout: 7000
      });
    }
  }, [pointNotification, houses]);

  // Handle Fireworkds
  useEffect(() => {
    const prevBlackout = prevIsBlack.current;
    const isBlackout = school.isBlackout;

    if (prevBlackout === true && !isBlackout) {
      setShowFireworks(true);

      fireworksTimerId.current = window.setTimeout(() => {
        setShowFireworks(false);
      }, 30000);
    }
    prevIsBlack.current = school.isBlackout;
  }, [school.isBlackout]);

  // Clear fireworks timer
  useEffect(() => {
    return () => {
      !!fireworksTimerId.current && clearTimeout(fireworksTimerId.current);
    };
  }, []);

  const isBlackout = school.isBlackout;
  const sortedHouses = isBlackout
    ? [...houses].sort((a, b) => (b.name < a.name ? 1 : -1))
    : [...houses].sort((a, b) => b.totalPoints - a.totalPoints);

  const firstPlace = sortedHouses[0];

  return (
    <>
      <TopAppBar className={styles.topAppBar} fixed>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarNavigationIcon icon="notes" onClick={onMenuToggle} />
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <TopAppBarActionItem
              icon="dynamic_feed"
              onClick={() => setPointsFeedOpen(!pointsFeedOpen)}
            />
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
      <NotificationsFeed
        pointsFeed={pointsFeed}
        houses={houses}
        open={pointsFeedOpen}
        onClose={() => setPointsFeedOpen(false)}
      />

      <View title={<div>{school.name}</div>} className={styles.view}>
        <Leaderboard houses={sortedHouses} isBlackout={isBlackout}>
          <Fade in={showFireworks}>
            <div>
              <FireworksParticle
                style={{ position: 'fixed', top: 0, left: 0 }}
                color={sortedHouses[0].color}
              />
              <div className={styles.winner}>
                {firstPlace.image && (
                  <img alt="First Place" src={firstPlace.image} />
                )}
                {firstPlace.name}
              </div>
            </div>
          </Fade>
        </Leaderboard>
      </View>
    </>
  );
}

function NotificationsFeed({
  pointsFeed,
  houses,
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
  pointsFeed: PointTransactionT[];
  houses: HouseT[];
}) {
  return (
    <Drawer modal dir="rtl" open={open} onClose={onClose}>
      <DrawerHeader dir="ltr">
        <DrawerTitle>Feed</DrawerTitle>
      </DrawerHeader>
      <DrawerContent dir="ltr">
        {pointsFeed.map(p => {
          const house = houses.find(h => h.id === p.houseId);

          if (house) {
            const { title, body } = formatNotificationMessage(house.name, p);
            return (
              <div key={p.id} className={styles.feedItem}>
                <div className={styles.feedItemValue}>+{p.awardValue}</div>
                <div className={styles.feedItemLabel}>
                  <Typography
                    tag="div"
                    use="body1"
                    className={styles.feedItemTitle}
                  >
                    {title}
                  </Typography>
                  <Typography tag="div" use="body2">
                    {body}
                  </Typography>
                  <div className={styles.feedTimestamp}>
                    {moment(p.createdAt).fromNow()}
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </DrawerContent>
    </Drawer>
  );
}
