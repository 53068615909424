import { StateT } from '@state';
import { createSelectors } from 'redux-state-branch';
import { name } from './defs';
import { HouseT, HouseStateT } from './defs';

export const { all, ...rest } = createSelectors<HouseT, HouseStateT>({
  name: name
});

export const bySchool = (
  state: StateT,
  { schoolId }: { schoolId: string | null }
) => {
  return all(state).filter(house => house.schoolId === schoolId);
};
