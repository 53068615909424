import { stateBranch } from 'redux-state-branch';
import * as actions from './actions';
import * as selectors from './selectors';
import { ClassroomT, ClassroomStateT, name } from './defs';

export * from './defs';

export const classroomBranch = stateBranch<ClassroomT, ClassroomStateT>()({
  name,
  actions,
  selectors
});
