import { StateT } from '@state';
import { createSelectors } from 'redux-state-branch';
import {
  SchoolT,
  SchoolStateT,
  SchoolsLoadingStateT,
  name,
  TimeframeT,
  TimeRangeT
} from './defs';
import { convertTimeframeToUnixMillisecondsRange } from './utils';
import { PointTransactionT } from '@state/point';

export const { byId, where } = createSelectors<SchoolT, SchoolStateT>({ name });

export const loading = (state: StateT): SchoolsLoadingStateT => {
  return state[name].loading;
};

export const selectedTimeframe = (state: StateT): TimeframeT => {
  return state[name].selectedTimeframe;
};

export const timeframes = (
  state: StateT,
  schoolId: string
): Array<[string, TimeRangeT]> => {
  const school = byId(state, { id: schoolId });
  const schoolTimeframes = school
    ? [
        ...Object.entries(school.timeframes.period)
          .reduce<any>((acc, [key, val]) => {
            acc.push([key, val]);
            return acc;
          }, [])

          .sort((a: any, b: any) => b[1][0] - a[1][0]),
        ['This Year', school.timeframes.year]
      ]
    : [];

  const defaultTimeframes = [
    ['Today', convertTimeframeToUnixMillisecondsRange('today')],
    ['This Week', convertTimeframeToUnixMillisecondsRange('thisWeek')],
    ['Last Week', convertTimeframeToUnixMillisecondsRange('lastWeek')],
    ['This Month', convertTimeframeToUnixMillisecondsRange('thisMonth')]
  ];

  return [...defaultTimeframes, ...schoolTimeframes];
};

export const publiclyVisible = (state: StateT): SchoolT[] => {
  return where(state, { callback: school => school.isPublic }).sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
};

export const pointsFeed = (
  state: StateT,
  schoolId: string
): PointTransactionT[] => {
  return state[name].items[schoolId].pointsFeed;
};

export const pointTransactions = (
  state: StateT,
  schoolId: string,
  timeRange: TimeRangeT = [-Infinity, Infinity]
): PointTransactionT[] => {
  const [startTime, endTime] = timeRange;
  const school = state[name].items[schoolId || ''];
  const points = Object.keys(school.points)
    .map((key): PointTransactionT => school.points[key])
    .filter(p => p.createdAt >= startTime && p.createdAt <= endTime)
    .sort((a, b) => b.createdAt - a.createdAt);
  return points;
};
