import React from 'react';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { Link } from 'react-router-dom';

type AccessDeniedPropsT = {
  /** A message for the user. */
  message: string;
  /** A place to send the user back to. */
  linkTo?: string;
  /** Text for the link */
  linkText?: string;
};

export const AccessDenied = ({
  message,
  linkTo,
  linkText = 'Back'
}: AccessDeniedPropsT) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div>
        <Icon icon="lock" style={{ fontSize: '4rem', color: '#ffcb2b' }} />
        <Typography use="headline6" tag="h2">
          {message}
        </Typography>
        {!!linkTo && (
          <Button tag={Link} {...{ to: linkTo }}>
            {linkText}
          </Button>
        )}
      </div>
    </div>
  );
};
