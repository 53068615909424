//@flow
import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '@common/auth';
import { Button } from '@rmwc/button';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarFixedAdjust
} from '@rmwc/top-app-bar';

const isIframe = window.self !== window.top;

export class Login extends React.Component<{}> {
  url: string;

  constructor(props: any) {
    super(props);
    this.url = auth.buildAuthUrl();
  }

  componentDidMount() {
    if (!isIframe) {
      auth.listenForLogin();
      window.addEventListener('storage', this.checkAuthenticated);
      this.checkAuthenticated();
    }
  }

  checkAuthenticated() {
    if (auth.isAuthenticated()) {
      window.removeEventListener('storage', this.checkAuthenticated);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.checkAuthenticated);
  }

  render() {
    if (isIframe) {
      return null;
    }

    return (
      <React.Fragment>
        <TopAppBar fixed>
          <TopAppBarRow>
            <TopAppBarSection>
              <Button tag={Link} {...{ to: '/' }} icon="arrow_back">
                Back
              </Button>
            </TopAppBarSection>
          </TopAppBarRow>
        </TopAppBar>
        <TopAppBarFixedAdjust />
        <iframe
          title="Login"
          src={this.url}
          style={{ width: '100vw', flex: 1, border: 0 }}
        />
      </React.Fragment>
    );
  }
}
