import React, { useEffect, useCallback } from 'react';
import { ClassroomMain } from './classroom-main';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, Redirect } from 'react-router';
import { StateT } from '@state';
import { SchoolT } from '@state/school';
import { classroomBranch } from '@state/classroom';
import { currentUserBranch } from '@state/current-user';
import { HouseT } from '@state/house';
import { pointBranch, ClassroomTimeRangeOptions } from '@state/point';
import { history } from '@common/history';

//hhs/educators/myclassroom/CZSrk0RCbqR0Ylt2oVCd/today
const navigateToClassroom = ({
  classroomId,
  schoolId,
  timeframe = ClassroomTimeRangeOptions.today
}: {
  classroomId: string;
  schoolId: string;
  timeframe?: string;
}) => {
  history.push(
    `/${schoolId}/educators/myclassroom/${classroomId}/${timeframe}`
  );
};

export function ClassroomContainer({
  school,
  classroomId,
  toggleMenu,
  houses,
  timeframe = ClassroomTimeRangeOptions.today
}: {
  school: SchoolT;
  toggleMenu: () => void;
  classroomId?: string;
  houses: HouseT[];
  timeframe?: ClassroomTimeRangeOptions;
}) {
  const match = useRouteMatch();

  const dispatch = useDispatch();

  const currentUser = useSelector((state: StateT) =>
    currentUserBranch.select.get(state)
  );

  const pointsMap = useSelector((state: StateT) =>
    pointBranch.select.classroomHousesPointsMap(state, {
      classroomId,
      timeframe
    })
  );

  const classroom = useSelector((state: StateT) =>
    classroomBranch.select.byId(state, { id: classroomId })
  );
  const classrooms = useSelector((state: StateT) =>
    classroomBranch.select.forUserAtSchool(state, currentUser.id, school.id)
  );

  const dispatchFetchClassroomsForUserAtSchool = useCallback(
    (userId: string) => {
      dispatch(classroomBranch.action.fetchForUserAtSchool(userId, school.id));
    },
    [dispatch, school.id]
  );

  const dispatchOnAddClassroom = useCallback(
    async (name: string) => {
      await dispatch(
        classroomBranch.action.newClassroom({
          schoolId: school.id,
          name,
          callback: classroom =>
            navigateToClassroom({
              classroomId: classroom.id,
              schoolId: school.id,
              timeframe
            })
        })
      );
    },
    [dispatch, school.id, timeframe]
  );

  const dispatchOnRemoveClassroom = useCallback(
    (classroomId: string) => {
      dispatch(classroomBranch.action.removeClassroom({ classroomId }));
      navigateToClassroom({
        classroomId,
        schoolId: school.id,
        timeframe
      });
    },
    [dispatch, school.id, timeframe]
  );

  const dispatchWatchClassroomPoints = useCallback(
    (classroomId: string) => {
      dispatch(
        pointBranch.action.fetchPointTransactions({
          schoolId: school.id,
          classroomId
        })
      );
    },
    [dispatch, school.id]
  );

  useEffect(() => {
    dispatchFetchClassroomsForUserAtSchool(currentUser.id);
  }, [dispatchFetchClassroomsForUserAtSchool, currentUser.id, school.id]);

  useEffect(() => {
    classroomId && dispatchWatchClassroomPoints(classroomId);
  }, [dispatchWatchClassroomPoints, classroomId]);

  if (classrooms.length && !classroomId) {
    return <Redirect to={`${match.url}/${classrooms[0].id}/today`} />;
  }

  if (!timeframe && classroomId) {
    return <Redirect to={`${match.url}/${classroomId}/today`} />;
  }

  return (
    <ClassroomMain
      school={school}
      houses={houses}
      pointsMap={pointsMap}
      classrooms={classrooms}
      classroom={classroom}
      timeframe={timeframe}
      onAddClassroom={dispatchOnAddClassroom}
      onMenuToggle={toggleMenu}
      onTimeframeChange={(timeframe: string) => {
        navigateToClassroom({
          classroomId: classroomId || '',
          schoolId: school.id,
          timeframe
        });
      }}
      onClassroomChange={classroomId => {
        navigateToClassroom({
          classroomId,
          schoolId: school.id,
          timeframe
        });
      }}
      onRemoveClassroom={dispatchOnRemoveClassroom}
    />
  );
}
