import { download } from './download';

export const arrayToCSVString = (data: Array<any[]>) =>
  data.reduce((acc, valArr, index) => {
    const val = valArr.map(v => {
      if (v === null || v === undefined) {
        return '';
      }
      return v;
    });
    const dataString = val.join(';');
    acc += index < data.length ? dataString + '\n' : dataString;
    return acc;
  }, '');

export const exportCSV = (
  data: Array<any[]>,
  filename: string = 'dowload.csv'
) => download(arrayToCSVString(data), filename, 'text/csv;encoding:utf-8');
